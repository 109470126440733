html {
	font-size: 10px;
}
html.popup-active {
	overflow: hidden;
}

body {
	font-family: 'Hind', sans-serif;
	font-weight: 400;
	line-height: 1.2;
	background-color: #e4e4e4;
	overflow: hidden;
}

button {
	outline: none;
	cursor: pointer;
}

* {
   -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}