.ticket-counter .tc-title.tc-title-bar-1 {
	top: 0;
	left: 0;
}

.ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-1 {
	top: 11.7rem;
	left: 0;
}
.ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-2 {
	top: 28.7rem;
	left: 0;
}
.ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-3 {
	top: 45.7rem;
	left: 0;
}
.ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-4 {
	top: 62.7rem;
	left: 0;
}
.ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-5 {
	top: 79.7rem;
	left: 0;
}

.ticket-counter .tc-element.main-ticket {
	top: 0;
	left: 68.6rem;
}

.ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-1 {
	top: 68.4rem;
	left: 68.6rem;
}
.ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-2 {
	top: 68.4rem;
	left: 93.3rem;
}
.ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-3 {
	top: 68.4rem;
	left: 118rem;
}
.ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-4 {
	top: 68.4rem;
	left: 142.7rem;
}
.ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-5 {
	top: 68.4rem;
	left: 167.4rem;
}

/* LANDSCAPE WITH ADS */
.with-ads .ticket-counter .ads {
	top: 0;
	left: 68.6rem;
}

/*
razvan
 */
.with-ads .ticket-counter .ads .media-parent {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
}
.with-ads .ticket-counter .ads .media-parent {
	display: -ms-flexbox;
	display: flex;

	-ms-flex-pack: center;
	justify-content: center;

	-ms-flex-align: center;
	align-items: center;
}
.with-ads .ticket-counter .ads img {
	opacity: 0;
}
.with-ads .ticket-counter .ads video {
	
}
/* LANDSCAPE WITH MAIN TICKET AND ADS */
.with-main-ticket:not(.portrait) .ticket-counter .tc-element.main-ticket {
	top: -68.4rem;
}

/* LANDSCAPE WITH MAIN TICKET FULL */
.with-main-ticket-full .ticket-counter .tc-element.main-ticket {
    top: 0;
    left: 0;
}
.top-ticker.with-main-ticket-full .ticket-counter .tc-element.main-ticket {
    top: -11.5rem;
}
.top-ticker.portrait.with-main-ticket-full .ticket-counter .tc-element.main-ticket {
    top: -12rem;
}


/* PORTRAIT */
.portrait .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-1 {
	top: 12rem;
	left: 0;
}
.portrait .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-2 {
	top: 36rem;
	left: 0;
}
.portrait .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-3 {
	top: 60rem;
	left: 0;
}
.portrait .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-4 {
	top: 84rem;
	left: 0;
}
.portrait .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-5 {
	top: 108rem;
	left: 0;
}
.portrait .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-6 {
	top: 132rem;
	left: 0;
}
.portrait .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-7 {
	top: 156rem;
	left: 0;
}

/* PORTRAIT WITH MAIN TICKET */
.portrait.with-main-ticket .ticket-counter .tc-element.main-ticket {
	top: -60.8rem;
	left: 0;
}

/* PORTRAIT WITH ADS */
.portrait.with-ads .ticket-counter .ads {
    top: 0;
    left: 0;
}

.portrait.with-ads .ticket-counter .tc-title.tc-title-bar-1 {
    top: 60.8rem;
}

.portrait.with-ads .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-1 {
	top: 72.8rem;
	left: 0;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-2 {
	top: 96.8rem;
	left: 0;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-3 {
	top: 120.8rem;
	left: 0;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-4 {
	top: 144.8rem;
	left: 0;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-5 {
	top: 168.8rem;
	left: 0;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-6 {
	top: 192.8rem;
	left: 0;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-1.ticket-bar-1-7 {
	top: 216.8rem;
	left: 0;
}

/* PORTRAIT THREE LINES */
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-1 {
	top: 0;
	left: 0;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-2 {
	top: 0;
	left: 36rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-3 {
	top: 0;
	left: 72rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-4 {
	top: 36rem;
	left: 0;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-5 {
	top: 36rem;
	left: 36rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-6 {
	top: 36rem;
	left: 72rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-7 {
	top: 72rem;
	left: 0;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-8 {
	top: 72rem;
	left: 36rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-9 {
	top: 72rem;
	left: 72rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-10 {
	top: 108rem;
	left: 0;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-11 {
	top: 108rem;
	left: 36rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-12 {
	top: 108rem;
	left: 72rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-13 {
	top: 144rem;
	left: 0;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-14 {
	top: 144rem;
	left: 36rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-15 {
	top: 144rem;
	left: 72rem;
}

/* PORTRAIT THREE LINES WITH ADS */
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-1 {
	top: 60.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-2 {
	top: 60.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-3 {
	top: 60.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-4 {
	top: 96.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-5 {
	top: 96.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-6 {
	top: 96.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-7 {
	top: 132.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-8 {
	top: 132.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-9 {
	top: 132.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-10 {
	top: 168.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-11 {
	top: 168.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-12 {
	top: 168.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-13 {
	top: 204.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-14 {
	top: 204.8rem;
}
.portrait.with-ads .ticket-counter .tc-element.ticket-bar-2.ticket-bar-2-15 {
	top: 204.8rem;
}

/* PORTRAIT WITH MAIN TICKET FULL */
.top-ticker.portrait.with-main-ticket-full .ticket-counter .tc-element.main-ticket {
    top: -12rem;
}