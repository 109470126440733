.popup-confirm-cancel {
	border-radius: 14px;

	padding-top: calc(19px + (57 - 19) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(19px + (57 - 19) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(11px + (33 - 11) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(11px + (33 - 11) * ((100vw - 350px) / (1019 - 350)));

	background: rgba(255,255,255,1);
	background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(231,230,230,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(231,230,230,1)));
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(231,230,230,1) 100%);
	background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(231,230,230,1) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(231,230,230,1) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(231,230,230,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e7e6e6', GradientType=0 );
}
.popup-confirm-cancel .parent-icon {
	text-align: center;

	margin-bottom: calc(4px + (14 - 4) * ((100vw - 350px) / (1019 - 350)));
}
.popup-confirm-cancel .parent-icon svg {
	width: calc(35px + (105 - 35) * ((100vw - 350px) / (1019 - 350)));
	height: calc(35px + (105 - 35) * ((100vw - 350px) / (1019 - 350)));
}
.popup-confirm-cancel .parent-icon svg .st11 {
	fill: #b0b0b0;
}
.popup-confirm-cancel .title {
	line-height: 1.3;
	font-weight: 600;
	color: #505050;

	font-size: calc(14px + (42 - 14) * ((100vw - 350px) / (1019 - 350)));
	margin-bottom: calc(6px + (20 - 6) * ((100vw - 350px) / (1019 - 350)));
}
.popup-confirm-cancel .guidance {
	line-height: 1.2;
	font-weight: 400;
	color: #505050;

	font-size: calc(12px + (37 - 12) * ((100vw - 350px) / (1019 - 350)));
	margin-bottom: calc(17px + (52 - 17) * ((100vw - 350px) / (1019 - 350)));
}
.popup-confirm-cancel .action-buttons {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-pack: center;
    justify-content: center;
}
.popup-confirm-cancel .action-buttons button {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;

    padding-top: 0;
	padding-bottom: 0;
	padding-left: 0.5rem;
    padding-right: 0.5rem;
	border: none;
	line-height: 1;
	font-weight: 400;
	letter-spacing: -0.1px;
	text-align: center;
	border-radius: 8px;
	transition: color 0.2s linear;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.51);

	width: calc(50% - 10px);

	height: calc(27px + (81 - 27) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(13px + (40 - 13) * ((100vw - 350px) / (1019 - 350)));
}
.popup-confirm-cancel .action-buttons button.confirm-action {
	background: rgba(33,201,167,1);
	background: -moz-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(33,201,167,1)), color-stop(100%, rgba(30,181,151,1)));
	background: -webkit-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: -o-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: -ms-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: linear-gradient(to bottom, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#21c9a7', endColorstr='#1eb597', GradientType=0 );

	color: #fff;
}
.popup-confirm-cancel .action-buttons button.confirm-action:hover {
	color: #505050;
}

.popup-confirm-cancel .action-buttons button.cancel-action {
	background: rgba(255,255,255,1);
	background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(17,12,14,0.15) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(17,12,14,0.15)));
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(17,12,14,0.15) 100%);
	background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(17,12,14,0.15) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(17,12,14,0.15) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(17,12,14,0.15) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#110c0e', GradientType=0 );

	color: #505050;
}
.popup-confirm-cancel .action-buttons button.cancel-action:hover {
	color: #f28900;
}
.popup-confirm-cancel .action-buttons button + button {
	margin-left: 2rem;
}
.popup-confirm-cancel .action-buttons button .texts {
	padding-top: 1px;
}

@media (min-width: 1020px) {
	.popup-confirm-cancel {
		padding-top: 5.7rem;
		padding-bottom: 5.7rem;
		padding-left: 3.3rem;
		padding-right: 3.3rem;
	}
	.popup-confirm-cancel .parent-icon {
		margin-bottom: 1.4rem;
	}
	.popup-confirm-cancel .parent-icon svg {
		width: 10.5rem;
		height: 10.5rem;
	}
	.popup-confirm-cancel .title {
		font-size: 4.2rem;
		margin-bottom: 2rem;
	}
	.popup-confirm-cancel .guidance {
		font-size: 3.7rem;
		margin-bottom: 5.2rem;
	}
	.popup-confirm-cancel .action-buttons button {
		height: 8.1rem;
		font-size: 4rem;
	}
}

@media (max-width: 800px) {
	.popup-confirm-cancel .action-buttons button {
		width: calc(50% - 7px);
	}
	.popup-confirm-cancel .action-buttons button + button {
		margin-left: 1.4rem;
	}
}

@media (max-width: 600px) {
	.popup-confirm-cancel .action-buttons button {
		width: calc(50% - 5px);
	}
	.popup-confirm-cancel .action-buttons button + button {
		margin-left: 1rem;
	}
}