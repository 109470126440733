.smartq-popup {
	z-index: 1005;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
  	opacity: 0;
  	visibility: hidden;
}

.react-swipeable-view-container .ticketPanel .smartq-popup {
	left: 100%;
}

.smartq-popup.active {
  	opacity: 1;
  	visibility: visible;
}
.smartq-popup .popup-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1001;
	background-color: rgba(0,0,0,.3);
}

.smartq-popup .popup-data {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1002;
}

.react-swipeable-view-container .ticketPanel .smartq-popup .popup-data {
	left: 100%;
}

.smartq-popup .popup-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1002;

	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;
}
.smartq-popup .popup-container .popup-content {
	-ms-flex: 1 1 auto;
    flex: 1 1 auto;

    text-align: center;
}
.smartq-popup .popup-container .popup-content .container {
	max-width: calc(310px + (930 - 310) * ((100vw - 350px) / (1019 - 350)));
	margin: 0 auto;
}
.smartq-popup .popup-container .popup-content .container .inside-content {
	border-radius: 14px;
	box-shadow: 10px 10px 50px 0px rgba(17,12,14,0.47);
}

@media (min-width: 1020px) {
	.smartq-popup .popup-container .popup-content .container {
		max-width: 93rem;
	}
}