@import url("../fonts/dispenser-single-font.css");
@import url("../main/reset.css");
@import url("../main/general.css");
@import url("../heading-content/heading-content.css");
@import url("../top-content/top-content.css");
@import url("../developer/developer.css");

.smartq-app-get-ticket {
	text-align: center;

	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;
}
.smartq-app-get-ticket .get-ticket-content {
	padding-top: calc(30px + (90 - 30) * ((100vw - 350px) / (1019 - 350)));
    padding-bottom: calc(30px + (90 - 30) * ((100vw - 350px) / (1019 - 350)));
    padding-left: calc(44px + (132 - 44) * ((100vw - 350px) / (1019 - 350)));
    padding-right: calc(44px + (132 - 44) * ((100vw - 350px) / (1019 - 350)));
}
.smartq-app-get-ticket .get-ticket-content .title {
	line-height: 1.2;
	font-weight: 400;
	letter-spacing: -0.1px;
	color: #505050;

	font-size: calc(25px + (75 - 25) * ((100vw - 350px) / (1019 - 350)));
	margin-bottom: calc(24px + (74 - 24) * ((100vw - 350px) / (1019 - 350)));
}

.smartq-app-get-ticket .get-ticket-content .press-here-element {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;
}
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content {
	position: relative;
}
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .press-here-button {
    border: 1px solid #505050;
    border-radius: 50%;

    width: calc(200px + (600 - 200) * ((100vw - 350px) / (1019 - 350)));
    height: calc(200px + (600 - 200) * ((100vw - 350px) / (1019 - 350)));
}
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .press-here-button .press-here-content {
    width: 100%;
    height: 100%;

    display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;
}
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .press-here-button .press-here-content .press-here-content-inside {
    width: calc(136px + (408 - 136) * ((100vw - 350px) / (1019 - 350)));
    height: calc(136px + (408 - 136) * ((100vw - 350px) / (1019 - 350)));
	
	border: none;
    border-radius: 50%;
    padding: 0;
    font-size: 0;
    line-height: 1;

    box-shadow: inset 16px 0px 16px 0px rgba(25,150,125,1), 
    			inset 0px -16px 16px 0px rgba(25,152,124,1), 
    			inset -16px 0px 16px 0px rgba(194,238,229,1), 
    			inset 0px 16px 16px 0px rgba(255,255,255,1), 
    			2px 2px 0px 0px rgba(17,12,14,.2);
    
    background-color: #20c1a0;

    display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;
}
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .press-here-button .press-here-content .press-here-content-inside .press-here {
	font-size: calc(20px + (60 - 20) * ((100vw - 350px) / (1019 - 350)));
	line-height: 1.1;
	font-weight: 600;
	letter-spacing: -0.25px;
	transition: color 0.2s linear;
    color: #fff;
}
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .press-here-button .press-here-content .press-here-content-inside:hover .press-here {
    color: #505050;
}
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .left-arrow,
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .right-arrow {
	font-size: 0;
	line-height: 1;

	width: calc(38px + (115 - 38) * ((100vw - 350px) / (1019 - 350)));
	height: calc(38px + (115 - 38) * ((100vw - 350px) / (1019 - 350)));

	position: absolute;
	top: 50%;
	color: #777;
	background-color: #e4e4e4;
}
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .left-arrow {
	left: 0;
	transform: translate(-50%, -50%);
}
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .right-arrow {
	right: 0;
	transform: translate(50%, -50%);
}
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .left-arrow:after,
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .right-arrow:after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin-top: 1px;
	font-family: 'Poppins', sans-serif;

	font-size: calc(29px + (87 - 29) * ((100vw - 350px) / (1019 - 350)));

	line-height: 1;
	font-weight: 300;
	letter-spacing: -7px;
}
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .left-arrow:after {
	content: '>>';
}
.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .right-arrow:after {
	content: '<<';
}

.smartq-app-get-ticket .get-ticket-content .announcement {
	line-height: 1.2;
	font-weight: 400;
	letter-spacing: -0.1px;
	color: #505050;

	font-size: calc(17px + (53 - 17) * ((100vw - 350px) / (1019 - 350)));
	margin-top: calc(46px + (140 - 46) * ((100vw - 350px) / (1019 - 350)));
}

@media (min-width: 1020px) {
	.smartq-app-get-ticket .get-ticket-content {
		padding-top: 9rem;
	    padding-bottom: 9rem;
	    padding-left: 13.2rem;
	    padding-right: 13.2rem;
	}
	.smartq-app-get-ticket .get-ticket-content .title {
		font-size: 7.5rem;
		margin-bottom: 7.4rem;
	}
	.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .press-here-button {
	    width: 60rem;
	    height: 60rem;
	}
	.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .press-here-button .press-here-content .press-here-content-inside {
	    width: 40.8rem;
	    height: 40.8rem;
	}
	.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .press-here-button .press-here-content .press-here-content-inside .press-here {
		font-size: 6rem;
	}
	.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .left-arrow,
	.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .right-arrow {
		width: 11.5rem;
		height: 11.5rem;
	}
	.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .left-arrow:after,
	.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .right-arrow:after {
		font-size: 8.7rem;
	}
	.smartq-app-get-ticket .get-ticket-content .announcement {
		font-size: 5.3rem;
		margin-top: 14rem;
	}
}

@media (max-width: 800px) {
	.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .press-here-button .press-here-content .press-here-content-inside {
	    box-shadow: inset 12px 0px 12px 0px rgba(25,150,125,1), 
	    			inset 0px -12px 12px 0px rgba(25,152,124,1), 
	    			inset -12px 0px 12px 0px rgba(194,238,229,1), 
	    			inset 0px 12px 12px 0px rgba(255,255,255,1), 
	    			2px 2px 0px 0px rgba(17,12,14,.2);
	}
}

@media (max-width: 600px) {
	.smartq-app-get-ticket .get-ticket-content .press-here-element .press-here-element-content .press-here-button .press-here-content .press-here-content-inside {
	    box-shadow: inset 8px 0px 8px 0px rgba(25,150,125,1), 
	    			inset 0px -8px 8px 0px rgba(25,152,124,1), 
	    			inset -8px 0px 8px 0px rgba(194,238,229,1), 
	    			inset 0px 8px 8px 0px rgba(255,255,255,1), 
	    			1px 1px 0px 0px rgba(17,12,14,.2);
	}
}