@import url("../fonts/main-font.css");
@import url("../main/reset.css");
@import url("../main/general.css");

.operator-calling-counter {
	background-color: #424242;
	width: 100%;
	height: 100%;
	top:0;
	right: 0;
	left:0;
	bottom:0;
	position: fixed;
	z-index: 9999;

	opacity: 0;
	visibility: hidden;
}

.landscape .title-operator-counter {
	display: -ms-flexbox;
	display: flex;

	-ms-flex-align: end;
    align-items: flex-end;

    height: 28vh;
	padding-left: 9rem;
    padding-right: 9rem;
}
.landscape .title-operator-counter .title {
    line-height: 1;
    font-weight: 300;
    letter-spacing: -0.25px;
	color: #fff;

	font-size: calc(37px + (107 - 37) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(17px + (51 - 17) * ((100vw - 350px) / (1019 - 350)));
}

.landscape .smartq-operator-counter {
	display: -ms-flexbox;
	display: flex;

	-ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;

    height: 72vh;
    font-size: 0;
    line-height: 1;
    margin-left: 9rem;
    margin-right: 9rem;
    overflow: hidden;
}
.landscape .smartq-operator-counter .smartq-operator-counter-content {
	line-height: 1;
	letter-spacing: -1px;
	color: #ffc801;

	font-size: calc(166px + (500 - 166) * ((100vw - 350px) / (1019 - 350)));
}

@media (min-width: 1200px) {
	.landscape .title-operator-counter .title {
		font-size: 10.7rem;
		padding-bottom: 5.1rem;
	}
	.landscape .smartq-operator-counter .smartq-operator-counter-content {
		font-size: 50rem;
	}
}

@media (max-width: 800px) {
	.landscape .title-operator-counter {
		padding-left: 6rem;
	    padding-right: 6rem;
	}
	.landscape .title-operator-counter .title {
	    padding-top: 6rem;
	    padding-bottom: 6rem;
	}
	.landscape .smartq-operator-counter {
	    margin-left: 6rem;
	    margin-right: 6rem;
	}
}

@media (max-width: 600px) {
	.landscape .title-operator-counter {
		padding-left: 3rem;
	    padding-right: 3rem;
	}
	.landscape .title-operator-counter .title {
	    padding-top: 3rem;
	    padding-bottom: 3rem;
	}
	.landscape .smartq-operator-counter {
	    margin-left: 3rem;
	    margin-right: 3rem;
	}
}