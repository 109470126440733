.heading-content .heading {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    position: relative;
    z-index: 1003;
    background-color: #fff;
}
.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.heading-content .heading .infinite-news {
	width: 100%;
	padding-left: 4rem;
	margin-right: 4rem;
	overflow: hidden;
}
.heading-content .heading .infinite-news .infinite-news-content{
	font-size: calc(20px + (60 - 20) * ((100vw - 350px) / (1019 - 350)));
	line-height: 1;
	font-weight: 500;
	letter-spacing: -0.25px;
	white-space: nowrap;
	padding-top: 0.9rem;
	overflow: hidden;

	/*display: inline-block;*/
}

.heading-content .heading .infinite-news .infinite-news-content .inlineBlockDisplayContinuous{
	display: inline-block;

}
.heading-content .heading .infinite-news .infinite-news-content .invisibleBlink{
	opacity: 0;
}

.heading-content .heading .infinite-news .infinite-news-content .inlineBlockDisplay{
	width: 100%;
	height: 100%;
}

.heading-content .heading .infinite-news .infinite-news-content .inlineBlockDisplay span {
	top:50%;
	transform: translateY(-50%);
	right: -100%;
	display: none;
}

.heading-content .heading .infinite-news .infinite-news-content .inlineBlockDisplay.activated span {
	position: static;
	top: auto;
	right: auto;
	transform: translateY(0%);display: inline-block;
}

.headingModified.common-color .heading {
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	opacity: 0.7;
}
.headingModified.common-color.bottom{
	position: fixed;
	bottom: 0;
	width: 100%;
}

.headingModified.common-color .heading .time {
	background-color: #ffc801;
	color: #000;
}

.heading-content .heading .time{
	-ms-flex-negative: 1;
    flex-shrink: 1;

    line-height: 1;
    padding-left: 5.1rem;
    padding-right: 5.1rem;
    padding-top: 3.1rem;
    padding-bottom: 1.7rem;
	background-color: #505050;
    color: #fff;
}
.heading-content .heading .time .time-content {
	font-size: calc(28px + (84 - 28) * ((100vw - 350px) / (1019 - 350)));
	line-height: 1;
	font-weight: 600;
    white-space: nowrap;
}



body.landscape .device-ads .heading-content .heading .infinite-news {
	padding-left: 2rem;
	margin-right: 2rem;
}
body.landscape .device-ads .heading-content .heading .infinite-news .infinite-news-content{
	font-size: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
	padding-top: 4px;
}
body.landscape .heading-content .heading .time{
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	padding-top: 1.5rem;
	padding-bottom: 0.8rem;
}
body.landscape .heading-content .heading .time .time-content {
	font-size: calc(14px + (42 - 14) * ((100vw - 350px) / (1019 - 350)));
}

@media (min-width: 1020px) {
	.heading-content .heading .infinite-news .infinite-news-content {
		font-size: 6rem;
	}
	.heading-content .heading .time .time-content {
		font-size: 8.4rem;
	}

	body.landscape .device-ads .heading-content .heading .infinite-news .infinite-news-content {
		font-size: 3rem;
	}
	body.landscape .device-ads .heading-content .heading .time .time-content {
		font-size: 4.2rem;
	}
}

@media (max-width: 800px) {
	.heading-content .heading .infinite-news {
		padding-left: 2.5rem;
		margin-right: 2.5rem;
	}
	.heading-content .heading .infinite-news .infinite-news-content {
		padding-top: 0.6rem;
	}
	.heading-content .heading .time {
	    padding-left: 3.4rem;
	    padding-right: 3.4rem;
	    padding-top: 2rem;
	    padding-bottom: 1rem;
	}

	body.landscape .device-ads .heading-content .heading .infinite-news {
		padding-left: 1.2rem;
		margin-right: 1.2rem;
	}
	body.landscape .device-ads .heading-content .heading .infinite-news .infinite-news-content {
		padding-top: 3px;
	}
	body.landscape .device-ads .heading-content .heading .time {
			padding-left: 1.7rem;
			padding-right: 1.7rem;
			padding-top: 1rem;
			padding-bottom: 0.5rem;
	}
}

@media (max-width: 600px) {
	.heading-content .heading .infinite-news {
		padding-left: 1.5rem;
		margin-right: 1.5rem;
	}
	.heading-content .heading .infinite-news .infinite-news-content {
		padding-top: 3px;
	}
	.heading-content .heading .time {
	    padding-left: 1.7rem;
	    padding-right: 1.7rem;
	    padding-top: 1rem;
	    padding-bottom: 0.5rem;
	}

	body.landscape .device-ads .heading-content .heading .infinite-news {
		padding-left: 1.2rem;
		margin-right: 1.2rem;
	}
	body.landscape .device-ads .heading-content .heading .infinite-news .infinite-news-content {
		padding-top: 1px;
	}
		body.landscape .device-ads .heading-content .heading .time {
			padding-left: 0.8rem;
			padding-right: 0.8rem;
			padding-top: 0.5rem;
			padding-bottom: 2px;
	}
}