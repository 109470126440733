.operator-lists > div {
	overflow:hidden;
}

.operator-lists .choose-tickets {
	display: -ms-flexbox!important;
	display: flex!important;

	-ms-flex-wrap: nowrap!important;
	flex-wrap: nowrap;
	width: 200%;
	position: relative;
	z-index: 1;
}
.operator-lists .choose-tickets .action-list {
	display: -ms-flexbox;
	display: flex;

	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.operator-lists .choose-tickets .action-list .left-side {
	-ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;

    margin-right: 1rem;
	padding: 0.5rem 0;
}
.operator-lists .choose-tickets .action-list .left-side button.disabled,
.operator-lists .choose-tickets .action-list .right-side button.disabled{
	opacity: 0.4;
}

.operator-lists .choose-tickets .action-list .left-side button {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

     -ms-flex-pack: center;
    justify-content: center;

	width: 100%;
	height: 100%;
	padding: 0;
	border: none;
	border-radius: 8px;

	line-height: 1;
	font-weight: 400;
	letter-spacing: -0.1px;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.31);

	font-size: calc(37px + (112 - 37) * ((100vw - 350px) / (1019 - 350)));

	transition: background-color 0.2s linear, color 0.2s linear;

	background-color: #f6f5f5;
	color: #505050;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.31);

	/*background: rgba(251,197,1,1);
	background: -moz-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(251,197,1,1)), color-stop(100%, rgba(242,137,0,1)));
	background: -webkit-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -o-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -ms-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: linear-gradient(to bottom, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbc501', endColorstr='#f28900', GradientType=0 );*/
}
.operator-lists .choose-tickets .action-list .left-side button:not(.disabled):hover {
	background-color: #929192;
	color: #fff;
}
.operator-lists .choose-tickets .action-list .left-side button .texts {
	padding-top: 2px;
}
.operator-lists .choose-tickets .action-list .right-side {
	-ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;

	display: -ms-flexbox;
	display: flex;

	-ms-flex-direction: column;
	flex-direction: column;

	-ms-flex-pack: justify;
	justify-content: space-between;

	margin-left: 1rem;
	padding: 0.5rem 0;
}
.operator-lists .choose-tickets .action-list .right-side button {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    padding-top: 0;
	padding-bottom: 0;
	border: none;
	line-height: 1;
	font-weight: 400;
	letter-spacing: -0.1px;
	text-align: right;
	border-radius: 8px;
	width: 100%;
	transition: background-color 0.2s linear, color 0.2s linear;
	background-color: #f6f5f5;
	color: #505050;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.31);

	font-size: calc(17px + (51 - 17) * ((100vw - 350px) / (1019 - 350)));
	height: calc(60px + (180 - 60) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(8px + (25 - 8) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(11px + (35 - 11) * ((100vw - 350px) / (1019 - 350)));
}
.operator-lists .choose-tickets .action-list .right-side button + button {
	margin-top: 2rem;
}
.operator-lists .choose-tickets .action-list .right-side button:not(.disabled):hover {
	background-color: #929192;
	color: #fff;
}
.operator-lists .choose-tickets .action-list .right-side button.active {
	background: rgba(251,197,1,1);
	background: -moz-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(251,197,1,1)), color-stop(100%, rgba(242,137,0,1)));
	background: -webkit-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -o-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -ms-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: linear-gradient(to bottom, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbc501', endColorstr='#f28900', GradientType=0 );

	color: #505050;
}
.operator-lists .choose-tickets .action-list .right-side button.active:hover {
	color: #fff;
}
.operator-lists .choose-tickets .action-list .right-side button .icon {
	font-size: 0;
	line-height: 1;
	letter-spacing: initial;
}
.operator-lists .choose-tickets .action-list .right-side button .icon svg {
	width: calc(23px + (70 - 23) * ((100vw - 350px) / (1019 - 350)));
	height: calc(23px + (70 - 23) * ((100vw - 350px) / (1019 - 350)));
}
.operator-lists .choose-tickets .action-list .right-side button .icon svg .st-12 {
	display: inline;
}
.operator-lists .choose-tickets .action-list .right-side button .icon svg .st-11,
.operator-lists .choose-tickets .action-list .right-side button .icon svg .st-18 {
	transition: fill 0.2s linear;
	fill: #b0b0b0;
}
.operator-lists .choose-tickets .action-list .right-side button:hover .icon svg .st-11,
.operator-lists .choose-tickets .action-list .right-side button:hover .icon svg .st-18 {
	fill: #fff;
}
.operator-lists .choose-tickets .action-list .right-side button.active .icon svg .st-11,
.operator-lists .choose-tickets .action-list .right-side button.active .icon svg .st-18 {
	fill: #505050;
}
.operator-lists .choose-tickets .action-list .right-side button.active:hover .icon svg .st-11,
.operator-lists .choose-tickets .action-list .right-side button.active:hover .icon svg .st-18 {
	fill: #fff;
}
.operator-lists .choose-tickets .action-list .right-side button .texts {
	margin-left: auto;

	padding-left: calc(5px + (15 - 5) * ((100vw - 350px) / (1019 - 350)));
}

@media (min-width: 1020px) {
	.operator-lists .choose-tickets .action-list .left-side button {
		font-size: 11.2rem;
	}
	.operator-lists .choose-tickets .action-list .right-side button {
		font-size: 5.1rem;
		height: 18rem;
		padding-left: 2.5rem;
		padding-right: 3.5rem;
	}
	.operator-lists .choose-tickets .action-list .right-side button .icon svg {
		width: 7rem;
		height: 7rem;
	}
	.operator-lists .choose-tickets .action-list .right-side button .texts {
		padding-left: 1.5rem;
	}
}

@media (max-width: 800px) {
	.operator-lists .choose-tickets .action-list .left-side {
	    margin-right: 0.7rem;
	}
	.operator-lists .choose-tickets .action-list .right-side {
		margin-left: 0.7rem;
	}
	.operator-lists .choose-tickets .action-list .right-side button + button {
		margin-top: 1.4rem;
	}
}

@media (max-width: 600px) {
	.operator-lists .choose-tickets .action-list .left-side {
	    margin-right: 0.5rem;
	}
	.operator-lists .choose-tickets .action-list .right-side {
		margin-left: 0.5rem;
	}
	.operator-lists .choose-tickets .action-list .right-side button + button {
		margin-top: 1rem;
	}
}