.operator-lists .dropped-tickets .dropped-lists {
	display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    max-height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;

	padding-right: 0.5rem;
}
.operator-lists .dropped-tickets .dropped-lists::-webkit-scrollbar {
	width: 1.4rem;
}
.operator-lists .dropped-tickets .dropped-lists::-webkit-scrollbar-track {
	background-image: repeating-linear-gradient(to right, transparent, transparent 5px, #b4b4b4 5px, #b4b4b4 9px, transparent 9px);
}
.operator-lists .dropped-tickets .dropped-lists::-webkit-scrollbar-thumb {
	background: #585858;
	border-radius: 6px;
}
.operator-lists .dropped-tickets .dropped-lists ul {
	width: 100%;
	padding-right: 2rem;

	margin-top: calc(5px + (15 - 5) * ((100vw - 350px) / (1019 - 350)));
	margin-bottom: calc(3px + (10 - 3) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(6px + (20 - 6) * ((100vw - 350px) / (1019 - 350)));
}
.operator-lists .dropped-tickets .dropped-lists ul li {
	line-height: 1.2;
	font-weight: 400;
	letter-spacing: -0.1px;

	font-size: calc(13px + (39 - 13) * ((100vw - 350px) / (1019 - 350)));
	padding-top: calc(6px + (18 - 6) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(4px + (13 - 4) * ((100vw - 350px) / (1019 - 350)));

	border-bottom: 1px solid #d5d5d5;
	color: #505050;
}
.operator-lists .dropped-tickets .dropped-lists ul li:first-child {
	padding-top: 0;
}
.operator-lists .dropped-tickets .dropped-lists ul li:last-child {
	padding-bottom: 0;
	border-bottom: none;
}

@media (min-width: 1020px) {
	.operator-lists .dropped-tickets .dropped-lists ul {
		margin-top: 1.5rem;
		margin-bottom: 1rem;
		padding-left: 2rem;
	}
	.operator-lists .dropped-tickets .dropped-lists ul li {
		font-size: 3.9rem;
		padding-top: 1.8rem;
		padding-bottom: 1.3rem;
	}
}

@media (max-width: 800px) {
	.operator-lists .dropped-tickets .dropped-lists {
		padding-right: 0.6rem;
	}
	.operator-lists .dropped-tickets .dropped-lists::-webkit-scrollbar {
		width: 1rem;
	}
	.operator-lists .dropped-tickets .dropped-lists::-webkit-scrollbar-track {
		background-image: repeating-linear-gradient(to right, transparent, transparent 4px, #b4b4b4 4px, #b4b4b4 6px, transparent 6px);
	}
	.operator-lists .dropped-tickets .dropped-lists::-webkit-scrollbar-thumb {
		border-radius: 4px;
	}
	.operator-lists .dropped-tickets .dropped-lists ul {
		padding-right: 1.8rem;
	}
}

@media (max-width: 600px) {
	.operator-lists .dropped-tickets .dropped-lists {
		padding-right: 0.7rem;
	}
	.operator-lists .dropped-tickets .dropped-lists::-webkit-scrollbar {
		width: 0.6rem;
	}
	.operator-lists .dropped-tickets .dropped-lists::-webkit-scrollbar-track {
		background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #b4b4b4 2px, #b4b4b4 4px, transparent 4px);
	}
	.operator-lists .dropped-tickets .dropped-lists::-webkit-scrollbar-thumb {
		border-radius: 2px;
	}
	.operator-lists .dropped-tickets .dropped-lists ul {
		padding-right: 1.6rem;
	}
}