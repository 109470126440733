@import url("../fonts/main-font.css");
@import url("../main/reset.css");
@import url("../main/general.css");
@import url("../heading-content/heading-content.css");

.landscape .smartq-rating-holder {
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 9999999999;
	top: 0;
	pointer-events: none;
}
.landscape .smartq-rating {
	position: relative;
	background: rgba(216,245,244,1);
	background: -moz-linear-gradient(left, rgba(216,245,244,1) 0%, rgba(201,238,237,1) 50%, rgba(247,252,254,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(216,245,244,1)), color-stop(50%, rgba(201,238,237,1)), color-stop(100%, rgba(247,252,254,1)));
	background: -webkit-linear-gradient(left, rgba(216,245,244,1) 0%, rgba(201,238,237,1) 50%, rgba(247,252,254,1) 100%);
	background: -o-linear-gradient(left, rgba(216,245,244,1) 0%, rgba(201,238,237,1) 50%, rgba(247,252,254,1) 100%);
	background: -ms-linear-gradient(left, rgba(216,245,244,1) 0%, rgba(201,238,237,1) 50%, rgba(247,252,254,1) 100%);
	background: linear-gradient(to right, rgba(216,245,244,1) 0%, rgba(201,238,237,1) 50%, rgba(247,252,254,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8f5f4', endColorstr='#f7fcfe', GradientType=1 );

	display: -ms-flexbox;
	display: flex;

	-ms-flex-align: center;
    align-items: center;

	padding-left: 9rem;
    padding-right: 9rem;

	height: 100%;
	position: absolute;

	opacity: 0;
	visibility: hidden;
}

.landscape .smartq-rating .smartq-rating-content {
	display: -ms-flexbox;
	display: flex;

	-ms-flex-align: center;
    align-items: center;

    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.landscape .smartq-rating .rating-info {
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;

	border: 6px solid #000;
	color: #000;

	padding: calc(12px + (35 - 12) * ((100vw - 350px) / (1019 - 350)));
}

.landscape .smartq-rating .rating-info .rating-number {
	line-height: 1;
	font-weight: 600;
	letter-spacing: -0.25px;

	font-size: calc(62px + (180 - 62) * ((100vw - 350px) / (1019 - 350)));
}

.landscape .smartq-rating .rating-info .operator-info {
	margin-top: calc(5px + (15 - 5) * ((100vw - 350px) / (1019 - 350)));
}

.landscape .smartq-rating .rating-info .operator-info .info {
	line-height: 1.2;
	font-weight: 400;
	letter-spacing: -0.1px;

	font-size: calc(17px + (50 - 17) * ((100vw - 350px) / (1019 - 350)));
}

.landscape .smartq-rating .rating-info .operator-info .operator {
	line-height: 1;
	font-weight: 600;
	letter-spacing: -0.25px;

	font-size: calc(28px + (80 - 28) * ((100vw - 350px) / (1019 - 350)));
	margin-top: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
}

.landscape .smartq-rating .rating-box {
	-ms-flex-item-align: start;
    align-self: flex-start;

	-webkit-flex: 3;
	-ms-flex: 3;
	flex: 3;

	text-align: center;
}

.landscape .smartq-rating .rating-box .invite-to-rate {
	line-height: 1.2;
	font-weight: 400;
	letter-spacing: -0.1px;
	color: #000;

	font-size: calc(26px + (75 - 26) * ((100vw - 350px) / (1019 - 350)));
	margin-left: calc(24px + (70 - 24) * ((100vw - 350px) / (1019 - 350)));
}

.landscape .smartq-rating .rating-box .rate-buttons {
	margin-top: calc(34px + (100 - 34) * ((100vw - 350px) / (1019 - 350)));
	margin-left: calc(24px + (70 - 24) * ((100vw - 350px) / (1019 - 350)));
}

.landscape .smartq-rating .rating-box .rate-buttons button {
	border: none;
	padding: 0;
	background-color: transparent;
	color: transparent;

	width: calc(41px + (120 - 41) * ((100vw - 350px) / (1019 - 350)));
	height: calc(41px + (120 - 41) * ((100vw - 350px) / (1019 - 350)));
	margin: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
}

@media (min-width: 1020px) {
	.landscape .smartq-rating .rating-info {
		padding: 3.5rem;
	}
	.landscape .smartq-rating .rating-info .rating-number {
		font-size: 18rem;
	}
	.landscape .smartq-rating .rating-info .operator-info {
		margin-top: 1.5rem;
	}
	.landscape .smartq-rating .rating-info .operator-info .info {
		font-size: 5rem;
	}
	.landscape .smartq-rating .rating-info .operator-info .operator {
		font-size: 8rem;
		margin-top: 3rem;
	}
	.landscape .smartq-rating .rating-box .invite-to-rate {
		font-size: 7.5rem;
		margin-left: 7rem;
	}
	.landscape .smartq-rating .rating-box .rate-buttons {
		margin-top: 10rem;
		margin-left: 7rem;
	}
	.landscape .smartq-rating .rating-box .rate-buttons button {
		width: 12rem;
		height: 12rem;
		margin: 3rem;
	}
}

@media (max-width: 800px) {
	.landscape .smartq-rating {
		padding-left: 6rem;
	    padding-right: 6rem;
	}
}

@media (max-width: 600px) {
	.landscape .smartq-rating {
		padding-left: 6rem;
	    padding-right: 6rem;
	}
}