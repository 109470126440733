@import url("../fonts/main-font.css");
@import url("../main/reset.css");
@import url("../main/general.css");
@import url("../heading-content/heading-content.css");
@import url("../top-content/top-content.css");
@import url("../developer/developer.css");
@import url("../popup/popup.css");
@import url("dispenser-popup.css");

.smartq-app-content {
	padding-left: 9rem;
	padding-right: 3.5rem;
}
.device-ads .smartq-app-content {
	padding-left: 0;
	padding-right: 0;
}
.smartq-app-content .smartq-app-container {
	max-height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
    padding-right: 3.5rem;
}
.smartq-app-content .smartq-app-container::-webkit-scrollbar {
	width: 2rem;
}
.smartq-app-content .smartq-app-container::-webkit-scrollbar-track {
	background-image: repeating-linear-gradient(to right, transparent, transparent 8px, #b4b4b4 8px, #b4b4b4 12px, transparent 12px);
}
.smartq-app-content .smartq-app-container::-webkit-scrollbar-thumb {
	background: #585858;
	border-radius: 9px;
}

.smartq-app-content .smartq-app-container .buttons-section {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.smartq-app-content .smartq-app-container .buttons-section button {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

	position: relative;
	padding-top: 0;
	padding-bottom: 0;
	border: none;
	line-height: 1;
	font-weight: 400;
	letter-spacing: -0.1px;
	text-align: left;
	border-radius: 8px;
	margin-bottom: 2rem;
	transition: background-color 0.2s linear, color 0.2s linear, box-shadow 0.2s linear;
	background-color: #c9c9c9;
	color: #505050;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0);

	height: calc(36px + (110 - 36) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(15px + (45 - 15) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(51px + (155 - 51) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(15px + (47 - 15) * ((100vw - 350px) / (1019 - 350)));
}
.smartq-app-content .smartq-app-container .buttons-section button:last-child {
	margin-bottom: 0;
}

.smartq-app-content .smartq-app-container .buttons-section button.disabledButton {
	opacity: 0.4;
}

.smartq-app-content .smartq-app-container .buttons-section button.active,
.smartq-app-content .smartq-app-container .buttons-section button:not(.disabledButton):hover {
	background-color: #6a6a6a;
	color: #fff;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.31);
}
.smartq-app-content .smartq-app-container .buttons-section button.full-width {
	width: 100%;
}
.smartq-app-content .smartq-app-container .buttons-section button.half-width {
	width: calc(50% - 10px);
}
.smartq-app-content .smartq-app-container .buttons-section button.half-width + button.half-width {
	margin-left: 2rem;
}
.smartq-app-content .smartq-app-container .buttons-section button .icon {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;

	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	font-size: 0;
	line-height: 1;
	letter-spacing: initial;
	border-radius: 0 8px 8px 0;

	background: rgba(251,197,1,1);
	background: -moz-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(251,197,1,1)), color-stop(100%, rgba(242,137,0,1)));
	background: -webkit-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -o-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -ms-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: linear-gradient(to bottom, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbc501', endColorstr='#f28900', GradientType=0 );

	width: calc(36px + (110 - 36) * ((100vw - 350px) / (1019 - 350)));
}
.smartq-app-content .smartq-app-container .buttons-section button:not(.active) .icon {
	display: none;
}
.smartq-app-content .smartq-app-container .buttons-section button .icon svg {
	width: calc(23px + (71 - 23) * ((100vw - 350px) / (1019 - 350)));
	height: calc(23px + (71 - 23) * ((100vw - 350px) / (1019 - 350)));
}
.smartq-app-content .smartq-app-container .buttons-section button .icon svg .st8 {
	transition: fill 0.2s linear;
	fill:#505050;
}
.smartq-app-content .smartq-app-container .buttons-section button:hover .icon svg .st8 {
	fill:#fff;
}

.bottom-content .print-and-language {
	display: -ms-flexbox;
    display: flex;

    padding-top: 4rem;
    padding-bottom: 9rem;
    padding-left: 9rem;
    padding-right: 9rem;
}
.bottom-content .print-and-language .print-ticket {
	width: 100%;
}
.bottom-content .print-and-language .print-ticket button.disabledButton {
	opacity: 0.4;
}
.bottom-content .print-and-language .print-ticket button {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;

	line-height: 1;
	font-weight: 600;
	letter-spacing: -0.25px;
	border: none;
	border-radius: 8px;

	background: rgba(33,201,167,1);
	background: -moz-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(33,201,167,1)), color-stop(100%, rgba(30,181,151,1)));
	background: -webkit-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: -o-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: -ms-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: linear-gradient(to bottom, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#21c9a7', endColorstr='#1eb597', GradientType=0 );

	color: #fff;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.51);
	
	height: calc(40px + (121 - 40) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(17px + (51 - 17) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
    padding-right: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));

    transition: color 0.2s linear;
}
.bottom-content .print-and-language .print-ticket button:not(.disabledButton):hover {
	color: #505050;
}
.bottom-content .print-and-language .print-ticket button .icon {
	font-size: 0;
	line-height: 1;
	letter-spacing: initial;
	margin-right: calc(8px + (25 - 8) * ((100vw - 350px) / (1019 - 350)));
}
.bottom-content .print-and-language .print-ticket button svg {
	width: calc(23px + (71 - 23) * ((100vw - 350px) / (1019 - 350)));
	height: calc(23px + (71 - 23) * ((100vw - 350px) / (1019 - 350)));

}
.bottom-content .print-and-language .print-ticket button svg .st7 {
	transition: fill 0.2s linear;
	fill: #fff;
}
.bottom-content .print-and-language .print-ticket button.active svg .st7,
.bottom-content .print-and-language .print-ticket button:not(.disabledButton):hover svg .st7 {
	fill: #505050;
}
.bottom-content .print-and-language .print-ticket button .texts {
	padding-top: 1px;
}

.bottom-content .print-and-language .change-language {
	-ms-flex-negative: 1;
    flex-shrink: 1;
}
.bottom-content .print-and-language .change-language .language-dropdown {
	position: relative;
}
.bottom-content .print-and-language .change-language .language-dropdown .language-dropdown-menu {
	position: absolute;
	bottom: 100%;
	left: 0;
	width: 100%;
}
.bottom-content .print-and-language .change-language .language-dropdown .language-dropdown-menu button {
	margin-bottom: 1rem;

	background-color: #c9c9c9;
}
.bottom-content .print-and-language .change-language .language-dropdown > button {
	background: rgba(255,255,255,1);
	background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(17,12,14,0.15) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(17,12,14,0.15)));
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(17,12,14,0.15) 100%);
	background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(17,12,14,0.15) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(17,12,14,0.15) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(17,12,14,0.15) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#110c0e', GradientType=0 );
}
.bottom-content .print-and-language .change-language button {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;

	line-height: 1;
	font-weight: 600;
	letter-spacing: -0.25px;
	text-transform: uppercase;
	border: none;
	border-radius: 4px;
	padding: 0 0.5rem;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.51);

	color: #888;

	height: calc(40px + (121 - 40) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(17px + (51 - 17) * ((100vw - 350px) / (1019 - 350)));
	width: calc(39px + (118 - 39) * ((100vw - 350px) / (1019 - 350)));

	transition: color 0.2s linear;
}
.bottom-content .print-and-language .change-language button:hover {
	color: #f28900;
}
.bottom-content .print-and-language .change-language button .texts {
	padding-top: 1px;
}

.smartq-app-content .smartq-app-container .buttons-section button .texts {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-top: 2px;
	padding-bottom: 2px;
}

@media (min-width: 1020px) {
	.smartq-app-content .smartq-app-container .buttons-section button {
		height: 11rem;
		padding-left: 4.5rem;
		padding-right: 15.5rem;
		font-size: 4.7rem;
	}
	.smartq-app-content .smartq-app-container .buttons-section button .icon {
		width: 11rem;
	}
	.smartq-app-content .smartq-app-container .buttons-section button .icon svg {
		width: 7.1rem;
		height: 7.1rem;
	}
	
	.bottom-content .print-and-language .print-ticket button {
		height: 12.1rem;
		font-size: 5.1rem;
		padding-left: 3rem;
		padding-right: 3rem;
	}
	.bottom-content .print-and-language .print-ticket button .icon {
		margin-right: 2.5rem;
	}
	.bottom-content .print-and-language .print-ticket button svg {
		width: 7.1rem;
		height: 7.1rem;
	}
	.bottom-content .print-and-language .change-language button {
		height: 12.1rem;
		font-size: 5.1rem;
		width: 11.8rem;
	}
}

@media (max-width: 800px) {
	.smartq-app-content {
		padding-left: 6rem;
		padding-right: 2rem;
	}
	.smartq-app-content .smartq-app-container {
	    padding-right: 2rem;
	}
	.smartq-app-content .smartq-app-container::-webkit-scrollbar {
		width: 1.4rem;
	}
	.smartq-app-content .smartq-app-container::-webkit-scrollbar-track {
		background-image: repeating-linear-gradient(to right, transparent, transparent 5px, #b4b4b4 5px, #b4b4b4 9px, transparent 9px);
	}
	.smartq-app-content .smartq-app-container::-webkit-scrollbar-thumb {
		border-radius: 6px;
	}
	.smartq-app-content .smartq-app-container .buttons-section button {
		margin-bottom: 1.4rem;
	}
	.smartq-app-content .smartq-app-container .buttons-section button.half-width {
		width: calc(50% - 7px);
	}
	.smartq-app-content .smartq-app-container .buttons-section button.half-width + button.half-width {
		margin-left: 1.4rem;
	}

	.bottom-content .print-and-language {
	    padding-top: 2.5rem;
	    padding-bottom: 6rem;
	    padding-left: 6rem;
	    padding-right: 6rem;
	}
}

@media (max-width: 600px) {
	.smartq-app-content {
		padding-left: 3rem;
		padding-right: 1.2rem;
	}
	.smartq-app-content .smartq-app-container {
	    padding-right: 1.2rem;
	}
	.smartq-app-content .smartq-app-container::-webkit-scrollbar {
		width: 0.6rem;
	}
	.smartq-app-content .smartq-app-container::-webkit-scrollbar-track {
		background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #b4b4b4 2px, #b4b4b4 4px, transparent 4px);
	}
	.smartq-app-content .smartq-app-container::-webkit-scrollbar-thumb {
		border-radius: 2px;
	}
	.smartq-app-content .smartq-app-container .buttons-section button {
		margin-bottom: 1rem;
	}
	.smartq-app-content .smartq-app-container .buttons-section button.half-width {
		width: calc(50% - 5px);
	}
	.smartq-app-content .smartq-app-container .buttons-section button.half-width + button.half-width {
		margin-left: 1rem;
	}

	.bottom-content .print-and-language {
	    padding-top: 1.5rem;
	    padding-bottom: 3rem;
	    padding-left: 3rem;
	    padding-right: 3rem;
	}
}