.bottom-content {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
}

.react-swipeable-view-container .ticketPanel .bottom-content {
	left: 100%;
}

.bottom-content .app-developer {
	height: 5.2rem;
	line-height: 1;
	background-color: #000;
}
.bottom-content .app-developer .developer-content {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: end;
    justify-content: flex-end;

    line-height: 1;

    padding-top: 1rem;
    padding-bottom: 0.8rem;
    padding-left: 9rem;
    padding-right: 9rem;
}
.bottom-content .app-developer .developer-content > .text {
	font-size: 1.3rem;
	margin-right: 1rem;

	line-height: 1;
	font-weight: 600;
	display: inline-block;
	color: #a1a1a1;
}
.bottom-content .app-developer .developer-content .developer-logo {
	width: 10.5rem;
}

.bottom-content .app-developer .developer-content .developer-logo .text{fill:rgba(136,136,136,.5);}
.bottom-content .app-developer .developer-content .developer-logo .blue-circle,.bottom-content .app-developer .developer-content .developer-logo .green-circle,.bottom-content .app-developer .developer-content .developer-logo .red-circle{fill-rule:evenodd;clip-rule:evenodd;fill:#ec1c24}
.bottom-content .app-developer .developer-content .developer-logo .blue-circle,.bottom-content .app-developer .developer-content .developer-logo .green-circle{fill:#00adee}
.bottom-content .app-developer .developer-content .developer-logo .green-circle{fill:#a5cd39}

@media (max-width: 800px) {
	.bottom-content .app-developer .developer-content {
	    padding-left: 6rem;
	    padding-right: 6rem;
	}
}

@media (max-width: 600px) {
	.bottom-content .app-developer .developer-content {
	    padding-left: 3rem;
	    padding-right: 3rem;
	}
}