.top-content .welcome {
	line-height: 1;
	padding-left: 9rem;
	padding-right: 9rem;
	padding-top: calc(36px + (108 - 36) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(19px + (58 - 19) * ((100vw - 350px) / (1019 - 350)));
}
.top-content .welcome .welcome-content {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: end;
    align-items: flex-end;
}
.top-content .welcome .welcome-content .title {
	font-size: 13.8rem;
	font-size: calc(46px + (138 - 46) * ((100vw - 350px) / (1019 - 350)));
	line-height: 1;
	font-weight: 400;
	letter-spacing: -0.25px;
	width: 100%;
	color: #616161;
}
.top-content .welcome .welcome-content .logo {
	-ms-flex-negative: 1;
    flex-shrink: 1;

    padding-bottom: calc(10px + (31 - 10) * ((100vw - 350px) / (1019 - 350)));
}
.top-content .welcome .welcome-content .logo .smartq-logo {
	width: calc(57px + (173 - 57) * ((100vw - 350px) / (1019 - 350)));
}
.top-content .welcome .font-by-device {
	font-size: calc(18px + (56 - 18) * ((100vw - 350px) / (1019 - 350)));
	line-height: 1;
	font-weight: 300;
	letter-spacing: -0.1px;
	color: #616161;
}

@media (min-width: 1020px) {
	.top-content .welcome {
		padding-top: 10.8rem;
		padding-bottom: 5.8rem;
	}
	.top-content .welcome .welcome-content .title {
		font-size: 13.8rem;
	}
	.top-content .welcome .welcome-content .logo {
	    padding-bottom: 3.1rem;
	}
	.top-content .welcome .welcome-content .logo .smartq-logo {
		width: 17.3rem;
	}
	.top-content .welcome .font-by-device {
		font-size: 5.6rem;
	}
}

@media (max-width: 800px) {
	.top-content .welcome {
		padding-left: 6rem;
		padding-right: 6rem;
	}
}

@media (max-width: 600px) {
	.top-content .welcome {
		padding-left: 3rem;
		padding-right: 3rem;
	}
}