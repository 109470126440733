/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local('Poppins Light'), local('Poppins-Light'),
       url('poppins/poppins-v6-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('poppins/poppins-v6-latin-300.woff') format('woff'), /* Modern Browsers */
       url('poppins/poppins-v6-latin-300.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* hind-300 - latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 300;
  src: local('Hind Light'), local('Hind-Light'),       
       url('hind/hind-v9-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('hind/hind-v9-latin-300.woff') format('woff'), /* Modern Browsers */
       url('hind/hind-v9-latin-300.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* hind-regular - latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 400;
  src: local('Hind Regular'), local('Hind-Regular'),

       url('hind/hind-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('hind/hind-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('hind/hind-v9-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */

}
/* hind-500 - latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  src: local('Hind Medium'), local('Hind-Medium'),
       url('hind/hind-v9-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('hind/hind-v9-latin-500.woff') format('woff'), /* Modern Browsers */
       url('hind/hind-v9-latin-500.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* hind-600 - latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  src: local('Hind SemiBold'), local('Hind-SemiBold'),
       url('hind/hind-v9-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('hind/hind-v9-latin-600.woff') format('woff'), /* Modern Browsers */
       url('hind/hind-v9-latin-600.ttf') format('truetype'); /* Safari, Android, iOS */
}