@import url("../fonts/main-font.css");
@import url("../main/reset.css");
@import url("../main/general.css");
@import url("../developer/developer.css");


.form-heading .logo .st0{fill:#FFC801;}
.form-heading .logo .st1{opacity:0.5;}
.form-heading .logo .st2{fill:url(#SVGID_1_);}
.form-heading .logo .st3{fill:#FFFFFF;}
.form-heading .logo .st4{filter:url(#Adobe_OpacityMaskFilter);}
.form-heading .logo .st5{clip-path:url(#SVGID_3_);fill:url(#SVGID_5_);}
.form-heading .logo .st6{opacity:0.6;clip-path:url(#SVGID_3_);mask:url(#SVGID_4_);fill:#FFFFFF;}

.loginNotif{
	background-color: red;
	font-size: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 70%;
	margin: 0 auto;
	opacity: 0;
}
 
.form-heading {
	border-bottom: 1px solid #c2c2c2;

	padding-top: calc(40px + (120 - 40) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(33px + (100 - 33) * ((100vw - 350px) / (1019 - 350)));
}

.form-heading .logo {
	font-size: 0;
	line-height: 1;
	text-align: center;
}
.form-heading .logo svg {
	width: calc(31px + (95 - 31) * ((100vw - 350px) / (1019 - 350)));
	height: calc(31px + (95 - 31) * ((100vw - 350px) / (1019 - 350)));
}
.smartq-app-login {
	text-align: center;

	padding-left: calc(36px + (110 - 36) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(36px + (110 - 36) * ((100vw - 350px) / (1019 - 350)));
}
.smartq-app-login .smartq-app-login-content {
	padding-top: calc(53px + (160 - 53) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(53px + (160 - 53) * ((100vw - 350px) / (1019 - 350)));
}
.smartq-app-login .smartq-app-login-content .form .title {
	line-height: 1.2;
	font-weight: 300;
	color: #505050;

	font-size: calc(32px + (97 - 32) * ((100vw - 350px) / (1019 - 350)));
	margin-bottom: calc(26px + (80 - 26) * ((100vw - 350px) / (1019 - 350)));
}
.smartq-app-login .smartq-app-login-content .form .form-block {
	padding-bottom: 3rem;
}
.smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-pack: center;
    justify-content: center;

	padding-top: 4rem;
	padding-bottom: 0;
}
.smartq-app-login .smartq-app-login-content .form .form-block .form-input {
	line-height: 1;
	font-weight: 400;
	text-align: center;
	background-color: #fff;
	color: #505050;
	box-shadow: inset 2px 2px 6px 0px rgba(17,12,14,0.26);
	border: none;
	border-radius: 8px;
	padding: 0;
	width: 100%;

	font-size: calc(12px + (37 - 12) * ((100vw - 350px) / (1019 - 350)));
	height: calc(34px + (102 - 34) * ((100vw - 350px) / (1019 - 350)));
}
.smartq-app-login .smartq-app-login-content .form .form-block .form-input:focus {
	outline-color: #ffc801;
}
.smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button {
	display: -ms-flexbox;
	display: flex;

	-ms-flex-align: center;
	align-items: center;

	-ms-flex-pack: center;
	justify-content: center;

	width: calc(50% - 10px);
	padding: 0.5rem;
	border: none;
	line-height: 1;
	font-weight: 500;
	letter-spacing: -0.1px;
	text-align: center;
	border-radius: 8px;
	transition: color 0.2s linear;
	color: #fff;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.31);

	background: rgba(80,80,80,1);
	background: -moz-linear-gradient(top, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(80,80,80,1)), color-stop(100%, rgba(69,69,69,1)));
	background: -webkit-linear-gradient(top, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	background: -o-linear-gradient(top, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	background: -ms-linear-gradient(top, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	background: linear-gradient(to bottom, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#505050', endColorstr='#454545', GradientType=0 );

  height: calc(34px + (102 - 34) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(13px + (40 - 13) * ((100vw - 350px) / (1019 - 350)));
}
.smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button:hover {
	color: #21c9a7;
}
.smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button:focus {
	outline-style: solid;
	outline-color: #ffc801;
	outline-width: 2px;
	outline-offset: 3px;
}
.smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button + button {
	margin-left: 2rem;
}

@media (min-width: 1020px) {
	.form-heading {
		padding-top: 12rem;
		padding-bottom: 10rem;
	}
	.form-heading .logo svg {
		width: 9.5rem;
		height: 9.5rem;
	}
	.smartq-app-login {
		padding-left: 11rem;
		padding-right: 11rem;
	}
	.smartq-app-login .smartq-app-login-content {
		padding-top: 16rem;
		padding-bottom: 16rem;
	}
	.smartq-app-login .smartq-app-login-content .form .title {
		font-size: 9.7rem;
		margin-bottom: 8rem;
	}
	.smartq-app-login .smartq-app-login-content .form .form-block .form-input {
		font-size: 3.7rem;
		height: 10.2rem;
	}
	.smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button {
	  height: 10.2rem;
		font-size: 4rem;
	}
}

@media (max-width: 800px) {
	.smartq-app-login .smartq-app-login-content .form .form-block {
		padding-bottom: 2rem;
	}
	.smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons {
		padding-top: 2.5rem;
	}
	.smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button {
		width: calc(50% - 7px);
	}
	.smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button + button {
		margin-left: 1.4rem;
	}
}

@media (max-width: 600px) {
	.smartq-app-login .smartq-app-login-content .form .form-block {
		padding-bottom: 1rem;
	}
	.smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons {
		padding-top: 1.5rem;
	}
	.smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button {
		width: calc(50% - 5px);
	}
	.smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button + button {
		margin-left: 1rem;
	}
}


@media (orientation: landscape) {
	body: {
		background: red;
	}
}


.landscape .smartq-app {
	display: -ms-flexbox;
	display: flex;
	height: 100vh;
}
.landscape .logo-holder {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-negative: 1;
	flex-shrink: 1;
	border-right: 1px solid #c2c2c2;
	padding-bottom: 5.2rem;
}
.landscape .logo-holder .form-heading {
	padding-top: 0;
	padding-bottom: 0;
	border-bottom-width: 0px;

	padding-left: calc(28px + (80 - 28) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(28px + (80 - 28) * ((100vw - 350px) / (1019 - 350)));
}
.landscape .logo-holder .form-heading .logo svg {
	width: calc(40px + (120 - 40) * ((100vw - 350px) / (1019 - 350)));
	height: calc(40px + (120 - 40) * ((100vw - 350px) / (1019 - 350)));
}
.landscape .smartq-app-login {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	padding-bottom: 5.2rem;
}
.landscape .smartq-app-login .smartq-app-login-content {
	width: 100%;
	padding-top: 3rem;
	padding-bottom: 3rem;
}
.landscape .smartq-app-login .smartq-app-login-content .form form {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.landscape .smartq-app-login .smartq-app-login-content .form .title {
	width: 100%;
	font-size: 10rem;
	margin-bottom: 5rem;

	font-size: calc(34px + (100 - 34) * ((100vw - 350px) / (1019 - 350)));
	margin-bottom: calc(17px + (50 - 17) * ((100vw - 350px) / (1019 - 350)));
}
.landscape .smartq-app-login .smartq-app-login-content .form .form-block-inputs {
	width: calc(50% - 15px);
	padding-right: 1.5rem;
}
.landscape .smartq-app-login .smartq-app-login-content .form .form-block-inputs .form-block:last-child {
	padding-bottom: 0;
}
.landscape .smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons {
	width: calc(50% - 15px);
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: start;
	justify-content: flex-start;
	padding-top: 0;
	padding-left: 1.5rem;
}
.landscape .smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button {
	width: 100%;
}
.landscape .smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button + button {
	margin-left: 0;
	margin-top: 3rem;
}

.landscape .smartq-app-login.smartq-app-setup .smartq-app-login-content .form .form-block-inputs {
	width: 100%;
	padding-right: 0;
}
.landscape .smartq-app-login.smartq-app-setup .smartq-app-login-content .form .form-block-inputs + .form-block.form-block-buttons {
	padding-top: 3rem;
}
.landscape .smartq-app-login.smartq-app-setup .smartq-app-login-content .form .form-block.form-block-buttons {
	width: 100%;
	padding-left: 0;
}

@media (min-width: 1020px) {
	.landscape .logo-holder .form-heading {
		padding-left: 8rem;
		padding-right: 8rem;
	}
	.landscape .logo-holder .form-heading .logo svg {
		width: 12rem;
		height: 12rem;
	}
	.landscape .smartq-app-login .smartq-app-login-content .form .title {
		font-size: 10rem;
		margin-bottom: 5rem;
	}
}

@media (max-width: 800px) {
	.landscape .smartq-app-login .smartq-app-login-content {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	.landscape .smartq-app-login .smartq-app-login-content .form .form-block-inputs {
		width: calc(50% - 10px);
		padding-right: 1rem;
	}
	.landscape .smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons {
		width: calc(50% - 10px);
		padding-left: 1rem;
	}
	.landscape .smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button + button {
		margin-top: 2rem;
	}

	.landscape .smartq-app-login.smartq-app-setup .smartq-app-login-content .form .form-block-inputs + .form-block.form-block-buttons {
		padding-top: 2rem;
	}
}

@media (max-width: 600px) {
	.landscape .smartq-app-login .smartq-app-login-content {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	.landscape .smartq-app-login .smartq-app-login-content .form .form-block-inputs {
		width: calc(50% - 5px);
		padding-right: 0.5rem;
	}
	.landscape .smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons {
		width: calc(50% - 5px);
		padding-left: 0.5rem;
	}
	.landscape .smartq-app-login .smartq-app-login-content .form .form-block.form-block-buttons button + button {
		margin-top: 1rem;
	}

	.landscape .smartq-app-login.smartq-app-setup .smartq-app-login-content .form .form-block-inputs + .form-block.form-block-buttons {
		padding-top: 1rem;
	}
}