@import url("../fonts/dispenser-single-font.css");
@import url("../main/reset.css");
@import url("../main/general.css");
@import url("operator-action-list.css");
@import url("operator-dropped-buttons.css");
@import url("operator-dropped-lists.css");
@import url("../developer/developer.css");
@import url("../popup/popup.css");
@import url("operator-popup.css");
@import url("confirm-cancel-popup.css");
@import url("resume-popup.css");
@import url("transfer-tickets-popup.css");

.logo-time {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    padding-top: calc(20px + (60 - 20) * ((100vw - 350px) / (1019 - 350)));
    padding-bottom: calc(17px + (51 - 17) * ((100vw - 350px) / (1019 - 350)));
    padding-left: calc(18px + (55 - 18) * ((100vw - 350px) / (1019 - 350)));
    padding-right: calc(18px + (55 - 18) * ((100vw - 350px) / (1019 - 350)));
}
.logo-time .logo {
	font-size: 0;
	line-height: 1;
}
.logo-time .logo svg {
	width: calc(22px + (68 - 22) * ((100vw - 350px) / (1019 - 350)));
	height: calc(22px + (68 - 22) * ((100vw - 350px) / (1019 - 350)));
}
.logo-time .logo img {
	max-height: calc(22px + (68 - 22) * ((100vw - 350px) / (1019 - 350)));
}
.logo-time .operator-intro {
	margin-left: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
}
.logo-time .operator-intro .title {
	line-height: 1;
	font-weight: 400;
	letter-spacing: -0.25px;
	color: #616161;
	
	padding-top: calc(4px + (12 - 4) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(27px + (82 - 27) * ((100vw - 350px) / (1019 - 350)));
}
.logo-time .time {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

	margin-left: auto;
}
.logo-time .time .popup-trigger {
	font-size: 0;
	line-height: 1;
    cursor: pointer;
}
.logo-time .time .popup-trigger svg {
	width: calc(30px + (90 - 30) * ((100vw - 350px) / (1019 - 350)));
	height: calc(30px + (90 - 30) * ((100vw - 350px) / (1019 - 350)));
}
.logo-time .time .popup-trigger svg .st13 {
	transition: fill 0.2s linear;
	fill: #414753;
}
.logo-time .time .popup-trigger:hover svg .st13 {
	fill: #f28900;
}
.logo-time .time  .popup-trigger + .time-content {
	margin-left: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
}
.logo-time .time .time-content {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

	font-size: 0;
	line-height: 1;
	border-radius: 9px;
	background-color: #505050;
	color: #fff;
	box-shadow: inset 3px 0px 10px 0px rgba(17,12,14,0.13);

	height: calc(30px + (90 - 30) * ((100vw - 350px) / (1019 - 350)));
}
.logo-time .time .time-content span {
	display: inline-block;
	font-weight: 600;
	pointer-events: none;

	font-size: calc(18px + (54 - 18) * ((100vw - 350px) / (1019 - 350)));
	padding-top: calc(3px + (8 - 3) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(12px + (36 - 12) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(12px + (36 - 12) * ((100vw - 350px) / (1019 - 350)));
}

.smartq-app-operator {
    padding-left: calc(18px + (55 - 18) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(18px + (55 - 18) * ((100vw - 350px) / (1019 - 350)));
}

.react-swipeable-view-container > div {
	overflow-x: hidden!important;
	position: relative;
	height: 100vh;
}

.operator-lists .choose-tickets .choose-list {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.operator-lists .choose-tickets .choose-list button {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;
	
	padding-top: 0;
	padding-bottom: 0;
	border: none;
	line-height: 1;
	font-weight: 400;
	letter-spacing: -0.1px;
	text-align: right;
	border-radius: 8px;
	margin-bottom: 2rem;
	width: calc(50% - 10px);
	transition: background-color 0.2s linear, color 0.2s linear;
	background-color: #f6f5f5;
	color: #505050;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.31);

	height: calc(60px + (180 - 60) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(8px + (25 - 8) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(11px + (35 - 11) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(17px + (51 - 17) * ((100vw - 350px) / (1019 - 350)));
}
.operator-lists .choose-tickets .choose-list button:nth-child(2n) {
	margin-left: 2rem;
}
.operator-lists .choose-tickets .choose-list button.disabled {
	opacity: 0.3;
}
.operator-lists .choose-tickets .choose-list button:not(.disabled):hover {
	background-color: #929192;
	color: #fff;
}
.operator-lists .choose-tickets .choose-list button.active {
	background: rgba(251,197,1,1);
	background: -moz-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(251,197,1,1)), color-stop(100%, rgba(242,137,0,1)));
	background: -webkit-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -o-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -ms-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: linear-gradient(to bottom, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbc501', endColorstr='#f28900', GradientType=0 );

	color: #505050;
}
.operator-lists .choose-tickets .choose-list button.active:hover {
	color: #fff;
}
.operator-lists .choose-tickets .choose-list button .icon {
	font-size: 0;
	line-height: 1;
	letter-spacing: initial;
}
.operator-lists .choose-tickets .choose-list button .icon svg {
	width: calc(23px + (70 - 23) * ((100vw - 350px) / (1019 - 350)));
	height: calc(23px + (70 - 23) * ((100vw - 350px) / (1019 - 350)));
}
.operator-lists .choose-tickets .choose-list button .icon svg .st8,
.operator-lists .choose-tickets .choose-list button .icon svg .st11 {
	transition: fill 0.2s linear;
	fill: #b0b0b0;
}
.operator-lists .choose-tickets .choose-list button:not(.disabled):hover .icon svg .st8,
.operator-lists .choose-tickets .choose-list button:not(.disabled):hover .icon svg .st11 {
	fill: #fff;
}
.operator-lists .choose-tickets .choose-list button.active .icon svg .st8,
.operator-lists .choose-tickets .choose-list button.active .icon svg .st11 {
	fill: #505050;
}
.operator-lists .choose-tickets .choose-list button.active:hover .icon svg .st8,
.operator-lists .choose-tickets .choose-list button.active:hover .icon svg .st11 {
	fill: #fff;
}
.operator-lists .choose-tickets .choose-list button .icon svg .st12 {
	display: inline;
}
.operator-lists .choose-tickets .choose-list button .texts {
	margin-left: auto;
	padding-left: calc(5px + (15 - 5) * ((100vw - 350px) / (1019 - 350)));
}

.operator-lists .dropped-tickets {
	border-radius: 8px;
	background-color: #bcbcbc;
	box-shadow: inset 5px 7px 14px 0px rgba(17,12,14,0.13);

	padding-top: 2rem;
	padding-bottom: 2rem;
	padding-left: 2rem;
	padding-right: 1.5rem;

	margin-top: calc(6px + (20 - 6) * ((100vw - 350px) / (1019 - 350)));
	margin-bottom: calc(20px + (60 - 20) * ((100vw - 350px) / (1019 - 350)));
}

.operator-lists .details-area {
	position: relative;
}
.operator-lists .details-area .details-area-input {
	line-height: 1.2;
	font-weight: 400;
	background-color: #fff;
	color: #505050;
	box-shadow: inset 2px 2px 6px 0px rgba(17,12,14,0.26);

	outline: none;
	border: none;
	resize: none;
	border-radius: 8px;
	padding: 2rem;
	width: calc(100% - 40px);

	font-size: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
	height: calc(67px + (201 - 67) * ((100vw - 350px) / (1019 - 350)));
}
.operator-lists .details-area .notification {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-pack: center;
    justify-content: center;

    -ms-flex-align: center;
    align-items: center;

	position: absolute;
	left: 0;
	bottom: -100%;
	width: 100%;
	border-radius: 8px;
	font-size: 0;
	line-height: 1;
	background-color: #2970c7;
	color: #fff;
	transition: bottom 0.2s linear;

	height: calc(17px + (51 - 17) * ((100vw - 350px) / (1019 - 350)));
}
.operator-lists .details-area.success-shown .notification {
	bottom: 20%;
}
.operator-lists .details-area .notification > span {
	padding-top: calc(1px + (3 - 1) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(8px + (24 - 8) * ((100vw - 350px) / (1019 - 350)));
}

@media (min-width: 1020px) {
	.logo-time {
		padding-top: 6rem;
		padding-bottom: 5.1rem;
	    padding-left: 5.5rem;
	    padding-right: 5.5rem;
	}
	.logo-time .logo svg {
		width: 6.8rem;
		height: 6.8rem;
	}
	.logo-time .logo img {
		max-height: 6.8rem;
	}
	.logo-time .operator-intro {
		margin-left: 3rem;
	}
	.logo-time .operator-intro .title {
		padding-top: 1.2rem;
		font-size: 8.2rem;
	}
	.logo-time .time .popup-trigger svg {
		width: 9rem;
		height: 9rem;
	}
	.logo-time .time  .popup-trigger + .time-content {
		margin-left: 3rem;
	}
	.logo-time .time .time-content {
		height: 9rem;
	}
	.logo-time .time .time-content span {
		font-size: 5.4rem;
		padding-top: 0.8rem;
		padding-left: 3.6rem;
		padding-right: 3.6rem;
	}

	.smartq-app-operator {
		padding-left: 5.5rem;
	    padding-right: 5.5rem;
	}

	.operator-lists .choose-tickets .choose-list button {
		height: 18rem;
		padding-left: 2.5rem;
		padding-right: 3.5rem;
		font-size: 5.1rem;
	}
	.operator-lists .choose-tickets .choose-list button .icon svg {
		width: 7rem;
		height: 7rem;
	}
	.operator-lists .choose-tickets .choose-list button .texts {
		padding-left: 1.5rem;
	}

	.operator-lists .dropped-tickets {
		margin-top: 2rem;
		margin-bottom: 6rem;
	}

	.operator-lists .details-area .details-area-input {
		font-size: 3rem;
		height: 20.1rem;
	}
	.operator-lists .details-area .notification {
		height: 5.1rem;
	}
	.operator-lists .details-area .notification > span {
		padding-top: 3px;
		font-size: 2.4rem;
	}
}

@media (max-width: 800px) {
	.operator-lists .choose-tickets .choose-list button {
		margin-bottom: 1.4rem;
		width: calc(50% - 7px);
	}
	.operator-lists .choose-tickets .choose-list button:nth-child(2n) {
		margin-left: 1.4rem;
	}
	
	.operator-lists .dropped-tickets {
		padding-right: 1.4rem;
	}
	
	.operator-lists .details-area .notification {
		border-radius: 6px;
	}
	.operator-lists .details-area .details-area-input {
		padding: 1.4rem;
		width: calc(100% - 28px);
	}
}

@media (max-width: 600px) {
	.operator-lists .choose-tickets .choose-list button {
		margin-bottom: 1rem;
		width: calc(50% - 5px);
	}
	.operator-lists .choose-tickets .choose-list button:nth-child(2n) {
		margin-left: 1rem;
	}
	
	.operator-lists .dropped-tickets {
		padding-right: 1.3rem;
	}
	.operator-lists .details-area .notification {
		border-radius: 4px;
	}
	.operator-lists .details-area .details-area-input {
		padding: 1rem;
		width: calc(100% - 20px);
	}
}