@import url("../fonts/main-font.css");
@import url("../main/reset.css");
@import url("../main/general.css");

body {
	background-color: transparent;
}

.smartq-app-content {
	position: relative;
	backface-visibility: hidden;
	overflow: hidden;
}

.smartq-app:not(.portrait) .smartq-app-content {
    height: 108rem;
}

.ticket-counter .tc-title {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

	position: absolute;
	width: 68.6rem;
	height: 11.7rem;
	font-size: 0;
	line-height: 1;
	background-color: #ffc801;
	color: #000;
}
.ticket-counter .tc-title .ticket-head,
.ticket-counter .tc-title .counter-head {
	-ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;

	font-size: 5.8rem;
	line-height: 1;
	font-weight: 600;
	letter-spacing: -0.25px;
	text-align: center;
	margin-top: 1rem;
}

.ticket-counter .tc-element {
	position: absolute;
}
.ticket-counter .tc-element.ticket-bar-horizontal {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

	width: 68.6rem;
	height: 17rem;
	background-color: #2c2c2c;
}
.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-recall {
	animation-name: changeBackgroundHorizontal;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;
}

@keyframes changeBackgroundHorizontal {
    from {background-color: #2c2c2c;}
    to {background-color: #ccc;}
}

.ticket-counter .tc-element.ticket-bar-horizontal .first-side,
.ticket-counter .tc-element.ticket-bar-horizontal .second-side {
	-ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
.ticket-counter .tc-element.ticket-bar-horizontal .first-side {
	text-align: center;
}
.ticket-counter .tc-element.ticket-bar-horizontal .first-side .ticket-title-number {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-direction: column;
    flex-direction: column;

    font-size: 0;
    line-height: 1;
}
.ticket-counter .tc-element.ticket-bar-horizontal .first-side .ticket-title-number .ticket-title {
	display: none;
}
.ticket-counter .tc-element.ticket-bar-horizontal .first-side .ticket-title-number .ticket-number {
	font-size: 9.8rem;
	line-height: 1;
	font-weight: 600;
	letter-spacing: -0.25px;
	margin-top: 1.6rem;
	color: #fff;
}

.ticket-counter .tc-element.ticket-bar-horizontal .second-side {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;
}
.ticket-counter .tc-element.ticket-bar-horizontal .second-side .tc-svgs {
	-ms-flex-negative: 1;
    flex-shrink: 1;
    font-size: 0;
    line-height: 1;

    padding-left: 4px;
}
.ticket-counter .tc-element.ticket-bar-horizontal .second-side .counter-title-number {
	width: 100%;
	font-size: 0;
	line-height: 1;
	text-align: center;
}
.ticket-counter .tc-element.ticket-bar-horizontal .second-side .counter-title-number .counter-title {
	display: none;
}
.ticket-counter .tc-element.ticket-bar-horizontal .second-side .counter-title-number .counter-number {
	font-size: 9.8rem;
	line-height: 1;
	font-weight: 500;
	letter-spacing: -0.25px;
	margin-top: 1.6rem;
	color: #fff;
}

.ticket-counter .tc-element.ticket-bar-horizontal .ticket-text {
	display: none;
}

.ticket-counter .tc-element .tc-svgs .tc-arrow {
	width: 4.4rem;
	height: 5.7rem;
}
.ticket-counter .tc-element .tc-svgs .tc-arrow .cls-1 {
	fill-rule: evenodd;
	fill: #ffc801;
}
.ticket-counter .tc-element.ticket-bar-horizontal .tc-svgs .tc-dot {
	display: none;
}
.ticket-counter .tc-element .tc-svgs .tc-dot {
	width: 1.5rem;
	height: 1.5rem;
}
.ticket-counter .tc-element .tc-svgs .tc-dot circle {
	fill: #ffc801;
}

.ticket-counter .tc-element.main-ticket {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-direction: column;
    flex-direction: column;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;

	width: 123.4rem;
	height: 68.4rem;
	font-size: 0;
	line-height: 1;
	background-color: #ffc801;
}

.smartq-app:not(.with-main-ticket):not(.with-main-ticket-full) .ticket-counter .tc-element.main-ticket {
	display: none;
}

.ticket-counter .tc-element.main-ticket .first-side .ticket-title-number {
	margin-top: 3.4rem;
}
.ticket-counter .tc-element.main-ticket .first-side .ticket-title-number .ticket-title {
	display: none;
}
.ticket-counter .tc-element.main-ticket .first-side .ticket-title-number .ticket-number {
	font-size: 25.6rem;
	line-height: 0.94;
	font-weight: 600;
	letter-spacing: -0.25px;
	color: #000;
}
.ticket-counter .tc-element.main-ticket .ticket-text {
	font-size: 6.2rem;
	line-height: 1;
	font-weight: 600;
	letter-spacing: -0.25px;
	color: #000;
}
.ticket-counter .tc-element.main-ticket .second-side .tc-svgs {
	display: none;
}
.ticket-counter .tc-element.main-ticket .second-side .counter-title-number {
	margin-top: 2rem;
}
.ticket-counter .tc-element.main-ticket .second-side .counter-title-number .counter-title {
	display: none;
}
.ticket-counter .tc-element.main-ticket .second-side .counter-title-number .counter-number {
	font-size: 19.5rem;
	line-height: 1;
	font-weight: 500;
	letter-spacing: -0.25px;
	color: #000;
}

.with-main-ticket.with-ads:not(.portrait):not(.skin-class) .ticket-counter .tc-element.main-ticket {
	animation-name: moveMainTicketLandscape;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;
}

@keyframes moveMainTicketLandscape {
    from {top: -68.4rem;}
    to {top: 0;}
}

.ticket-counter .ads {
	position: absolute;
	width: 123.4rem;
	height: 68.4rem;
	font-size: 0;
	line-height: 1;
	overflow: hidden;
}
.ticket-counter .ads img {
	max-width: 100%;
	max-height: 100%;
}

.ticket-counter .tc-element.ticket-bar-vertical {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-direction: column;
    flex-direction: column;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;
	
	font-size: 0;
	line-height: 1;
	width: 24.7rem;
	height: 28.1rem;
	background-color: #252525;
}
.ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-alternate {
	background-color: #2c2c2c;
}

.ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-recall {
	animation-name: changeBackgroundVertical;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;
}

@keyframes changeBackgroundVertical {
    from {background-color: #252525;}
    to {background-color: #ccc;}
}

.ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-alternate.ticket-bar-vertical-alternate-recall {
	animation-name: changeBackgroundVerticalAlternate;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;
}

@keyframes changeBackgroundVerticalAlternate {
    from {background-color: #2c2c2c;}
    to {background-color: #ccc;}
}

.ticket-counter .tc-element.ticket-bar-vertical .first-side .ticket-title-number .ticket-title {
	display: none;
}
.ticket-counter .tc-element.ticket-bar-vertical .first-side .ticket-title-number .ticket-number {
	font-size: 7.2rem;
	line-height: 1.06;
	font-weight: 600;
	letter-spacing: -0.25px;
	color: #ffc801;
}

.ticket-counter .tc-element.ticket-bar-vertical .ticket-text {
	display: none;
}

.ticket-counter .tc-element.ticket-bar-vertical .second-side .tc-svgs {
	text-align: center;
}
.ticket-counter .tc-element.ticket-bar-vertical .second-side .tc-svgs .tc-arrow {
	display: none;
}
.ticket-counter .tc-element.ticket-bar-vertical .second-side .counter-title-number {
	margin-top: 1.9rem
}
.ticket-counter .tc-element.ticket-bar-vertical .second-side .counter-title-number .counter-title {
	display: none;
}
.ticket-counter .tc-element.ticket-bar-vertical .second-side .counter-title-number .counter-number {
	font-size: 6rem;
	line-height: 1;
	font-weight: 500;
	letter-spacing: -0.25px;
	color: #ffc801;
}


/* FULL FORMATING */
.ticket-counter .tc-title.tc-title-full {
	width: 100%;
}

.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-full {
	-ms-flex-align: initial;
    align-items: initial;
	
	width: 100%;
	height: 21.2rem;
	background-color: transparent;
}
.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-full .first-side {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;

	background-color: #2c2c2c;
}
.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-full .first-side .ticket-title-number {
	font-size: 13rem;
	margin-top: 2rem;
}
.ticket-counter .tc-element.ticket-bar-horizontal-full .tc-svgs .tc-arrow {
	width: 7.2rem;
	height: 9.4rem;
}
.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-full .second-side {
	background-color: #252525;
}
.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-full .second-side .counter-title-number .counter-number {
	font-size: 13rem;
	margin-top: 2rem;
}
.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-full .second-side .tc-svgs {
	padding-left: 2.8rem;
}


/* BIG FORMATING */
.ticket-counter .tc-title.tc-title-big {
	width: 50%;
}

.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-big {
	width: 50%;
	height: 21.2rem;
}
.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-big .first-side .ticket-title-number .ticket-number {
	font-size: 13rem;
	margin-top: 2rem;
}

.ticket-counter .tc-element.ticket-bar-horizontal-big .tc-svgs .tc-arrow {
	width: 7.2rem;
	height: 9.4rem;
}

.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-big .second-side .counter-title-number .counter-number {
	font-size: 13rem;
	margin-top: 2rem;
}

.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-big .second-side .tc-svgs {
	padding-left: 1rem;
}

.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-big.ticket-bar-horizontal-alternate {
	background-color: #252525;
}


/* SIDE FORMATING */
.ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-big {
	width: 28rem;
	height: 32.2rem;
}
.ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-big .first-side {
	margin-top: 1.8rem;
}
.ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-big .first-side .ticket-title-number .ticket-title {
	font-size: 3.8rem;
	line-height: 1;
	font-weight: 500;
	letter-spacing: -0.25px;
	text-align: center;
	color: #fff;
	display: block;
}
.ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-big .first-side .ticket-title-number .ticket-number {
    font-size: 7.8rem;
    line-height: 1;
    margin-top: 1.7rem;
    color: #ffc801;
}

.ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-big .second-side .counter-title-number {
    margin-top: 1.7rem;
}
.ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-big .second-side .counter-title-number .counter-title {
    font-size: 3.8rem;
	line-height: 1;
	font-weight: 500;
	letter-spacing: -0.25px;
	text-align: center;
	color: #fff;
	display: block;
}
.ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-big .second-side .counter-title-number .counter-number {
    font-size: 7.8rem;
    text-align: center;
    margin-top: 1.5rem;
    color: #fff;
}


/* SKIN FORMATING */
.skin-class .ticket-counter .ads {
	width: 164rem;
	height: 96.5rem;
}

.skin-class .ticket-counter .tc-element.main-ticket {
	width: 164rem;
	height: 96.5rem;
}

.skin-class.with-main-ticket.with-ads .ticket-counter .tc-element.main-ticket {
	animation-name: moveMainTicketSideLandscape;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;
}

@keyframes moveMainTicketSideLandscape {
    from {top: -96.5rem;}
    to {top: 0;}
}


/* ONE TICKET FORMATING */
.with-main-ticket-full .ticket-counter .tc-element.main-ticket {
    -ms-flex-direction: row;
    flex-direction: row;

    -ms-flex-align: initial;
    align-items: initial;
    
    -ms-flex-pack: initial;
    justify-content: initial;

    -ms-flex-align: stretch;
    align-items: stretch;

	width: 100%;
	height: 108rem;
	background-color: transparent;
}
.with-main-ticket-full .ticket-counter .tc-element.main-ticket .first-side {
	-ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;

    display: -ms-flexbox;
    display: flex;

    -ms-flex-pack: center;
    justify-content: center;

    -ms-flex-align: center;
    align-items: center;

    padding-top: 6.8rem;

	background-color: #ffc801;
}
.with-main-ticket-full .ticket-counter .tc-element.main-ticket .first-side .ticket-title-number {
	text-align: center;
    margin-top: 0;
}
.with-main-ticket-full .ticket-counter .tc-element.main-ticket .first-side .ticket-title-number .ticket-title {
	font-size: 10.5rem;
	line-height: 1;
	font-weight: 600;
	letter-spacing: -0.25px;
    display: block;
    color: #000;
}
.with-main-ticket-full .ticket-counter .tc-element.main-ticket .first-side .ticket-title-number .ticket-number {
    font-size: 32.1rem;
    line-height: 1;
    margin-top: 3.9rem;
}

.with-main-ticket-full .ticket-counter .tc-element.main-ticket .ticket-text {
    display: none;
}

.with-main-ticket-full .ticket-counter .tc-element.main-ticket .second-side {
	-ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;

    display: -ms-flexbox;
    display: flex;

    -ms-flex-pack: center;
    justify-content: center;

    -ms-flex-align: center;
    align-items: center;

    padding-top: 6.8rem;

	background-color: #252525;
}
.with-main-ticket-full .ticket-counter .tc-element.main-ticket .second-side .counter-title-number {
	text-align: center;
    margin-top: 0;
}
.with-main-ticket-full .ticket-counter .tc-element.main-ticket .second-side .counter-title-number .counter-title {
    font-size: 10.5rem;
	line-height: 1;
	font-weight: 600;
	letter-spacing: -0.25px;
    display: block;
    color: #fff;
}
.with-main-ticket-full .ticket-counter .tc-element.main-ticket .second-side .counter-title-number .counter-number {
    font-size: 32.1rem;
    font-weight: 600;
    margin-top: 3.9rem;
    color: #ffc801;
}

.with-main-ticket-full .ticket-counter .tc-element.main-ticket {
	z-index: 1;
}

.with-main-ticket-full:not(.portrait) .ticket-counter .tc-element.main-ticket:not(.main-ticket-reverse) .first-side {
	animation-name: moveMainTicketFirstSideLandscape;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;

	transform: translateX(-100%);
}
.with-main-ticket-full:not(.portrait) .ticket-counter .tc-element.main-ticket.main-ticket-reverse .first-side {
	animation-name: moveMainTicketFirstSideLandscapeReverse;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;
}

.with-main-ticket-full:not(.portrait) .ticket-counter .tc-element.main-ticket:not(.main-ticket-reverse) .second-side {
	animation-name: moveMainTicketSecondSideLandscape;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;

	transform: translateX(100%);
}

.with-main-ticket-full:not(.portrait) .ticket-counter .tc-element.main-ticket.main-ticket-reverse .second-side {
	animation-name: moveMainTicketSecondSideLandscapeReverse;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;
}

@keyframes moveMainTicketFirstSideLandscape {
    from {transform: translateX(-100%);}
    to {transform: translateX(0);}
}
@keyframes moveMainTicketFirstSideLandscapeReverse {
	from {transform: translateX(0);}
	to {transform: translateX(-100%);}
}

@keyframes moveMainTicketSecondSideLandscape {
    from {transform: translateX(100%);}
    to {transform: translateX(0);}
}
@keyframes moveMainTicketSecondSideLandscapeReverse {
	from {transform: translateX(0);}
	to {transform: translateX(100%);}
}


/* PORTRAIT FULL FORMATING */
.portrait .smartq-app-content {
    height: 192rem;
}

.portrait .ticket-counter .tc-title {
	width: 100%;
	height: 12rem;
}

.portrait .ticket-counter .tc-element.ticket-bar-horizontal {
	width: 100%;
	height: 24rem;
	background-color: #2c2c2c;
}
.portrait .ticket-counter .tc-element.ticket-bar-horizontal .first-side {
	background-color: #2c2c2c;
}
.portrait .ticket-counter .tc-element.ticket-bar-horizontal .first-side .ticket-title-number .ticket-number {
    font-size: 15rem;
    margin-top: 3px;
}

.portrait .ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-full .second-side .tc-svgs {
    padding-left: 1rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-horizontal-full .tc-svgs .tc-arrow {
    width: 7.8rem;
    height: 10.2rem;
}

.portrait .ticket-counter .tc-element.ticket-bar-horizontal .second-side {
	background-color: #252525;
}
.portrait .ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-full .second-side .counter-title-number .counter-number {
    font-size: 15rem;
    margin-top: 2.4rem;
}

.portrait .ticket-counter .tc-element.main-ticket {
    width: 100%;
    height: 60.8rem;
    z-index: 1;
}

/* PORTRAIT WITH MAIN TICKET AND ADS */
.portrait.with-main-ticket .ticket-counter .tc-element.main-ticket {
	animation-name: moveMainTicket;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;
}

.portrait.with-main-ticket:not(.with-ads) .ticket-counter .tc-title-bar-1,
.portrait.with-main-ticket:not(.with-ads) .ticket-counter .tc-title-bar-2,
.portrait.with-main-ticket:not(.with-ads) .ticket-counter .tc-element.ticket-bar-1,
.portrait.with-main-ticket:not(.with-ads) .ticket-counter .tc-element.ticket-bar-2 {
	animation-name: moveTickets;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;
}

.portrait.with-main-ticket .ticket-counter .tc-title-bar-1,
.portrait.with-main-ticket .ticket-counter .tc-title-bar-2,
.portrait.with-main-ticket .ticket-counter .tc-element.ticket-bar-1 .first-side,
.portrait.with-main-ticket .ticket-counter .tc-element.ticket-bar-2 .first-side,
.portrait.with-main-ticket .ticket-counter .tc-element.ticket-bar-1 .second-side,
.portrait.with-main-ticket .ticket-counter .tc-element.ticket-bar-2 .second-side {
	backface-visibility: hidden;
}

@keyframes moveMainTicket {
    from {top: -60.8rem;}
    to {top: 0;}
}

@keyframes moveTickets {
    from {transform: translateY(0);}
    to {transform: translateY(60.8rem);}
}

/* PORTRAIT WITH ADS */
.portrait.with-ads .ticket-counter .ads {
    width: 100%;
    height: 60.8rem;
}

/* PORTRAIT BIG FORMATING */
.portrait .ticket-counter .tc-title.tc-title-big {
	width: 50%;
}

.portrait .ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-big {
    width: 50%;
    height: 16.8rem;
}

.portrait .ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-big .first-side {
	background-color: transparent;
}
.portrait .ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-big .first-side .ticket-title-number .ticket-number {
    font-size: 8rem;
    margin-top: 1.2rem;
}

.portrait .ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-big .second-side {
    background-color: transparent;
}
.portrait .ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-big .second-side .tc-svgs {
    padding-left: 3px;
}
.portrait .ticket-counter .tc-element.ticket-bar-horizontal-big .tc-svgs .tc-arrow {
    width: 4.3rem;
    height: 5.7rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-big .second-side .counter-title-number .counter-number {
    font-size: 8rem;
    margin-top: 1.2rem;
}

/* PORTRAIT ONE TICKET FORMATING */
.portrait.with-main-ticket-full .ticket-counter .tc-element.main-ticket {
	-ms-flex-direction: column;
    flex-direction: column;

    -ms-flex-align: initial;
    align-items: initial;

    height: 192rem;
}

.portrait.with-main-ticket-full .ticket-counter .tc-element.main-ticket .first-side {
	animation-name: moveMainTicketFirstSidePortrait;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;

	transform: translateY(-100%);
}

.portrait.with-main-ticket-full .ticket-counter .tc-element.main-ticket .second-side {
	animation-name: moveMainTicketSecondSidePortrait;
	animation-duration: 0.3s;
	animation-timing-function: ease-out;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;

	transform: translateY(100%);
}

@keyframes moveMainTicketFirstSidePortrait {
    from {transform: translateY(-100%);}
    to {transform: translateY(0);}
}

@keyframes moveMainTicketSecondSidePortrait {
    from {transform: translateY(100%);}
    to {transform: translateY(0);}
}

/* PORTRAIT THREE LINES */
.portrait .ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-big {
	width: 36rem;
	height: 36rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-big .first-side .ticket-title-number {
    margin-bottom: 0.7rem;
}
.portrait .ticket-counter .tc-element.ticket-bar-vertical.ticket-bar-vertical-big .second-side .counter-title-number {
    margin-top: 2.6rem;
}


.tc-news-section {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
}
.tc-news-section .news-content {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;

    -ms-flex-align: center;
    align-items: center;

    background-color: #fff;
}
.tc-news-section .news-content .time {
	-ms-flex-negative: 1;
    flex-shrink: 1;

    font-size: 0;
    line-height: 1;
    padding-top: 2.5rem;
    padding-bottom: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #ffc801;
}
.tc-news-section .news-content .time .time-content {
	font-size: 7.8rem;
	line-height: 1;
	font-weight: 600;
	letter-spacing: -0.25px;
	text-align: center;
	width: 22.7rem;
	color: #000;
}
.tc-news-section .news-content .infinite-news {
	width: 100%;
	font-size: 0;
    line-height: 1;
	overflow: hidden;
	margin-left: 5rem;
	padding-right: 5rem;
}
.tc-news-section .news-content .infinite-news .infinite-news-content {
	font-size: 5.6rem;
	line-height: 1;
	font-weight: 500;
	letter-spacing: -0.25px;
	white-space: nowrap;
	padding-top: 0.8rem;
	color: #000;
}


/* PORTRAIT TICKER */
.portrait .tc-news-section .news-content .time {
    padding-top: 2.7rem;
    padding-bottom: 1.5rem;
}
.portrait .tc-news-section .news-content .infinite-news .infinite-news-content {
	padding-top: 1rem;
}


/* TOP TICKER FORMATING */
.top-ticker .ticket-counter {
	position: relative;
	margin-top: 11.5rem;
}
.top-ticker .tc-news-section {
	top: 0;
	bottom: auto;
}

.top-ticker.portrait .ticket-counter {
	margin-top: 12rem;
}

.top-ticker.portrait:not(.with-main-ticket-full) .ticket-counter .tc-element.main-ticket {
    z-index: initial;
}

/* LEFT TIME TICKER FORMATING */
.left-time-ticker .tc-news-section .news-content {
    -ms-flex-direction: row;
    flex-direction: row;
}
.left-time-ticker .tc-news-section .news-content .infinite-news {
	margin-left: 0;
	margin-right: 5rem;
	padding-right: 0;
	padding-left: 5rem;
}

/*
razvan
 */
.ticket-bar-horizontal-empty {
	opacity: 0;
}

.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-empty .first-side,
.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-empty .ticket-text,
.ticket-counter .tc-element.ticket-bar-horizontal.ticket-bar-horizontal-empty .second-side {
	display: none;
}