.popup-operator-content {
	position: relative;
	border-radius: 14px;

	background: rgba(222,222,222,1);
	background: -moz-linear-gradient(top, rgba(222,222,222,1) 0%, rgba(201,199,200,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(222,222,222,1)), color-stop(100%, rgba(201,199,200,1)));
	background: -webkit-linear-gradient(top, rgba(222,222,222,1) 0%, rgba(201,199,200,1) 100%);
	background: -o-linear-gradient(top, rgba(222,222,222,1) 0%, rgba(201,199,200,1) 100%);
	background: -ms-linear-gradient(top, rgba(222,222,222,1) 0%, rgba(201,199,200,1) 100%);
	background: linear-gradient(to bottom, rgba(222,222,222,1) 0%, rgba(201,199,200,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dedede', endColorstr='#c9c7c8', GradientType=0 );
}
.popup-operator-content .popup-trigger {
	position: absolute;
	right: 0;
	border-radius: 6px;
	cursor: pointer;

	top: calc(-41px + (-124 + 41) * ((100vw - 350px) / (1019 - 350)));
	width: calc(41px + (124 - 41) * ((100vw - 350px) / (1019 - 350)));
	height: calc(41px + (124 - 41) * ((100vw - 350px) / (1019 - 350)));

	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;

	background: rgba(251,197,1,1);
	background: -moz-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(251,197,1,1)), color-stop(100%, rgba(242,137,0,1)));
	background: -webkit-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -o-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -ms-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: linear-gradient(to bottom, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbc501', endColorstr='#f28900', GradientType=0 );
}
.popup-operator-content .popup-trigger svg {
	width: calc(16px + (50 - 16) * ((100vw - 350px) / (1019 - 350)));
	height: calc(16px + (50 - 16) * ((100vw - 350px) / (1019 - 350)));
}
.popup-operator-content .popup-trigger svg .st13 {
	transition: fill 0.2s linear;
	fill: #505050;
}
.popup-operator-content .popup-trigger:hover svg .st13 {
	fill: #fff;
}


.popup-operator-content .popup-title {
	line-height: 1.2;
	font-weight: 500;
	color: #505050;
	border-bottom: 1px solid #c2c2c2;

	font-size: calc(16px + (48 - 16) * ((100vw - 350px) / (1019 - 350)));
	padding-top: calc(15px + (45 - 15) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(12px + (37 - 12) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(23px + (69 - 23) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(23px + (69 - 23) * ((100vw - 350px) / (1019 - 350)));
}
.popup-operator-content .popup-title .title .title-text,
.popup-operator-content .popup-title .title .title-text-alternate {
	display: inline-block;
}
.popup-operator-content .popup-title .title .title-text {
	transform: translateX(50%);
	opacity: 0;
}
.popup-operator-content .popup-title .title .title-text-alternate {
	transform: translateX(-50%);
	opacity: 0;
}

.popup-operator-content .popup-title .title .title-text-enter-done,
.popup-operator-content .popup-title .title .title-text-appear-active{
	opacity: 1;
	transition: opacity 0.2s linear;
}

.popup-operator-content .popup-title .title-text-exit-done {
	opacity: 0;
	transition: opacity 0.2s linear;
}


.popup-operator-content .popup-title .title .title-text-alternate-enter-done {
	opacity: 1;
	transition: opacity 0.2s linear;
	transition-delay: 0.2s;
}

.popup-operator-content .popup-title .title .title-text-alternate-exit-active {
	opacity: 0;
	transition: opacity 0.2s linear;
}

.popup-operator-content .name-input-holder {
	padding-top: calc(17px + (52 - 17) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(23px + (69 - 23) * ((100vw - 350px) / (1019 - 350)));
    padding-right: calc(23px + (69 - 23) * ((100vw - 350px) / (1019 - 350)));
}
.popup-operator-content .name-input-holder input {
	line-height: 1;
    font-weight: 400;
    text-align: center;
    background-color: #fff;
    color: #505050;
    box-shadow: inset 2px 2px 6px 0px rgba(17,12,14,0.26);
    outline: none;
    border: none;
    border-radius: 8px;
    padding: 0;
    width: 100%;
    font-size: calc(12px + (37 - 12) * ((100vw - 350px) / (1019 - 350)));
    height: calc(34px + (102 - 34) * ((100vw - 350px) / (1019 - 350)));
}

.popup-operator-content .ticket-data.ticket-data-exit-done {
	opacity: 0;
	transition: opacity 0.2s linear;
}


.popup-operator-content .ticket-data.ticket-data-enter-done {
	opacity: 1;
	transition: opacity 0.2s linear;
	transition-delay: 0.2s;
}

.popup-operator-content .ticket-data {
	opacity: 0;
	font-size: 0;
	line-height: 1;

	padding-top: calc(17px + (52 - 17) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(17px + (52 - 17) * ((100vw - 350px) / (1019 - 350)));
}
.popup-operator-content .ticket-data .title {
	line-height: 1.2;
	font-weight: 400;
	color: #505050;

	font-size: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
}
.popup-operator-content .ticket-data .title .title-name {
	font-weight: 600;
}
.popup-operator-content .ticket-data ul {

	margin-top: calc(14px + (21 - 14) * ((100vw - 350px) / (1019 - 350)));
}
.popup-operator-content .ticket-data ul li {
	line-height: 1.2;
	font-weight: 400;
	color: #505050;

	font-size: calc(14px + (21 - 14) * ((100vw - 350px) / (1019 - 350)));
}
.popup-operator-content .ticket-data ul li + li {
	margin-top: calc(3px + (9 - 3) * ((100vw - 350px) / (1019 - 350)));
}

.popup-operator-content .buttons-section {
	padding-top: calc(27px + (81 - 27) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(23px + (69 - 23) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(23px + (69 - 23) * ((100vw - 350px) / (1019 - 350)));
}
.popup-operator-content .buttons-section button {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;
	
	padding-top: 0;
	padding-bottom: 0;
	border: none;
	line-height: 1;
	font-weight: 400;
	letter-spacing: -0.1px;
	text-align: right;
	border-radius: 8px;
	margin-bottom: 2rem;
	width: 100%;
	transition: background-color 0.2s linear, color 0.2s linear;
	background-color: #f6f5f5;
	color: #505050;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.31);

	height: calc(36px + (109 - 36) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(8px + (25 - 8) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(11px + (35 - 11) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(13px + (40 - 13) * ((100vw - 350px) / (1019 - 350)));
}
.popup-operator-content .buttons-section button:not(.disabled):hover {
	background-color: #929192;
	color: #fff;
}
.popup-operator-content .buttons-section button.disabled {
	display: none;
}

.popup-operator-content .buttons-section button.active {
	background: rgba(251,197,1,1);
	background: -moz-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(251,197,1,1)), color-stop(100%, rgba(242,137,0,1)));
	background: -webkit-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -o-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -ms-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: linear-gradient(to bottom, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbc501', endColorstr='#f28900', GradientType=0 );

	color: #505050;
}
.popup-operator-content .buttons-section button.active:hover {
	color: #fff;
}
.popup-operator-content .buttons-section button .icon {
	font-size: 0;
	line-height: 1;
	letter-spacing: initial;
}
.popup-operator-content .buttons-section button .icon svg {
	width: calc(23px + (70 - 23) * ((100vw - 350px) / (1019 - 350)));
	height: calc(23px + (70 - 23) * ((100vw - 350px) / (1019 - 350)));
}
.popup-operator-content .buttons-section button .icon svg .st11,
.popup-operator-content .buttons-section button .icon svg .st14 {
	transition: fill 0.2s linear;
	fill: #b0b0b0;
}
.popup-operator-content .buttons-section button:hover .icon svg .st11,
.popup-operator-content .buttons-section button:hover .icon svg .st14 {
	fill: #fff;
}
.popup-operator-content .buttons-section button.active .icon svg .st11,
.popup-operator-content .buttons-section button.active .icon svg .st14 {
	fill: #505050;
}
.popup-operator-content .buttons-section button.active:hover .icon svg .st11,
.popup-operator-content .buttons-section button.active:hover .icon svg .st14 {
	fill: #fff;
}
.popup-operator-content .buttons-section button .texts {
	margin-left: auto;

	padding-left: calc(5px + (15 - 5) * ((100vw - 350px) / (1019 - 350)));
}

.popup-operator-content .buttons-section .numpad_holder.disabled {
	display: none;
}

.popup-operator-content .buttons-section .numpad_holder .numpad_result_parent {
	margin-bottom: 2rem;
}
.popup-operator-content .buttons-section .numpad_holder .numpad_result_parent .numpad_result {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-pack: center;
    justify-content: center;

    -ms-flex-align: center;
    align-items: center;

    font-weight: 400;
    line-height: 1;
    text-align: center;
    background-color: #fff;
    color: #505050;
    box-shadow: inset 2px 2px 6px 0px rgba(17,12,14,0.26);
    border-radius: 8px;

    font-size: calc(13px + (40 - 13) * ((100vw - 350px) / (1019 - 350)));
    height: calc(24px + (73 - 24) * ((100vw - 350px) / (1019 - 350)));
    padding-top: calc(3px + (8 - 3) * ((100vw - 350px) / (1019 - 350)));
}

.popup-operator-content .buttons-section .numpad .numpad_buttons {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    -ms-flex-pack: justify;
    justify-content: space-between;

    margin-bottom: -2rem;
}
.popup-operator-content .buttons-section .numpad .numpad_buttons .button_numpad {
	width: auto;
	margin-left: 1rem;
	margin-right: 1rem;

	-ms-flex-pack: center;
    justify-content: center;

	-webkit-flex: 3;
	-ms-flex: 3;
	flex: 3;

	height: calc(27px + (81 - 27) * ((100vw - 350px) / (1019 - 350)));
    font-size: calc(13px + (40 - 13) * ((100vw - 350px) / (1019 - 350)));
    padding-left: calc(35px + (105 - 35) * ((100vw - 350px) / (1019 - 350)));
    padding-right: calc(35px + (105 - 35) * ((100vw - 350px) / (1019 - 350)));
}
.popup-operator-content .buttons-section .numpad .numpad_buttons .button_numpad:nth-child(3n+1) {
	margin-left: 0;
}
.popup-operator-content .buttons-section .numpad .numpad_buttons .button_numpad:nth-child(3n+3) {
	margin-right: 0;
}
.popup-operator-content .buttons-section .numpad .numpad_buttons .button_numpad:nth-last-child(1) {
	margin-right: 0;
}

.popup-operator-content .action-buttons {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-pack: center;
    justify-content: center;

	padding-top: calc(17px + (52 - 17) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(19px + (57 - 19) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(23px + (69 - 23) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(23px + (69 - 23) * ((100vw - 350px) / (1019 - 350)));
}

.popup-operator-content .action-buttons-exit-active {
	opacity: 0;
	transition: opacity 0.2s linear;
}

.popup-operator-content .action-buttons button {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    line-height: 1;
	font-weight: 400;
	letter-spacing: -0.1px;
	border: none;
	border-radius: 8px;
	padding-top: 0;
	padding-bottom: 0;
	color: #fff;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.51);
	transition: color 0.2s linear;

    height: calc(27px + (81 - 27) * ((100vw - 350px) / (1019 - 350)));
    font-size: calc(13px + (40 - 13) * ((100vw - 350px) / (1019 - 350)));
    padding-left: calc(35px + (105 - 35) * ((100vw - 350px) / (1019 - 350)));
    padding-right: calc(35px + (105 - 35) * ((100vw - 350px) / (1019 - 350)));

    background: rgba(80,80,80,1);
	background: -moz-linear-gradient(top, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(80,80,80,1)), color-stop(100%, rgba(69,69,69,1)));
	background: -webkit-linear-gradient(top, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	background: -o-linear-gradient(top, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	background: -ms-linear-gradient(top, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	background: linear-gradient(to bottom, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#505050', endColorstr='#454545', GradientType=0 );
}
.popup-operator-content .action-buttons button:not(.disabled):hover {
	color: #21c9a7;
}
.popup-operator-content .action-buttons button.disabled {
	opacity: 0.4;
	cursor: auto;
}
.popup-operator-content .action-buttons button + button {
	margin-left: 3rem;
}

.popup-operator-content .name-input-holder-exit-active {
	opacity: 0;
	transition: opacity 0.2s linear;
}


@media (min-width: 1020px) {
	.popup-operator-content .popup-trigger {
		top: -12.4rem;
		width: 12.4rem;
		height: 12.4rem;
	}
	.popup-operator-content .popup-trigger svg {
		width: 5rem;
		height: 5rem;
	}
	.popup-operator-content .popup-title {
		font-size: 4.8rem;
		padding-top: 4.5rem;
		padding-bottom: 3.7rem;
		padding-left: 6.9rem;
		padding-right: 6.9rem;
	}

	.popup-operator-content .name-input-holder {
		opacity: 1;
		padding-top: 5.2rem;
		padding-left: 6.9rem;
		padding-right: 6.9rem;
	}
	.popup-operator-content .name-input-holder input {
	    font-size: 3.7rem;
	    height: 10.2rem;
	}

	.popup-operator-content .ticket-data {
		padding-top: 5.2rem;
		padding-bottom: 5.2rem;
	}
	.popup-operator-content .ticket-data .title {
		font-size: 3rem;
	}
	.popup-operator-content .ticket-data ul {
		margin-top: 2.1rem;
	}
	.popup-operator-content .ticket-data ul li {
		font-size: 2.1rem;
	}
	.popup-operator-content .ticket-data ul li + li {
		margin-top: 0.9rem;
	}

	.popup-operator-content .buttons-section {
		padding-top: 8.1rem;
		padding-left: 6.9rem;
		padding-right: 6.9rem;
	}
	.popup-operator-content .buttons-section button {
		height: 10.9rem;
		padding-left: 2.5rem;
		padding-right: 3.5rem;
		font-size: 4rem;
	}
	.popup-operator-content .buttons-section button .icon svg {
		width: 7rem;
		height: 7rem;
	}
	.popup-operator-content .buttons-section button .texts {
		padding-left: 1.5rem;
	}

	.popup-operator-content .buttons-section .numpad_holder .numpad_result_parent .numpad_result {
	    height: 7.3rem;
		font-size: 4rem;
		padding-top: 0.8rem;
	}
	.popup-operator-content .buttons-section .numpad .numpad_buttons .button_numpad {
		height: 8.1rem;
		font-size: 4rem;
		padding-left: 10.5rem;
	    padding-right: 10.5rem;
	}

	.popup-operator-content .action-buttons {
		padding-top: 5.2rem;
		padding-left: 6.9rem;
		padding-right: 6.9rem;
		padding-bottom: 5.7rem;
	}
	.popup-operator-content .action-buttons button {
		height: 8.1rem;
		font-size: 4rem;
		padding-left: 10.5rem;
	    padding-right: 10.5rem;
	}
}

@media (max-width: 800px) {
	.popup-operator-content .buttons-section button {
		margin-bottom: 1.4rem;
	}
	.popup-operator-content .buttons-section .numpad_holder .numpad_result_parent {
		margin-bottom: 1.4rem;
	}
	.popup-operator-content .buttons-section .numpad .numpad_buttons {
	    margin-bottom: -1.4rem;
	}
	.popup-operator-content .buttons-section .numpad .numpad_buttons .button_numpad {
		margin-left: 0.7rem;
		margin-right: 0.7rem;
	}
	.popup-operator-content .action-buttons button + button {
		margin-left: 2.2rem;
	}
}

@media (max-width: 600px) {
	.popup-operator-content .buttons-section button {
		margin-bottom: 1rem;
	}
	.popup-operator-content .buttons-section .numpad_holder .numpad_result_parent {
		margin-bottom: 1rem;
	}
	.popup-operator-content .buttons-section .numpad .numpad_buttons {
	    margin-bottom: -1rem;
	}
	.popup-operator-content .buttons-section .numpad .numpad_buttons .button_numpad {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
	.popup-operator-content .action-buttons button + button {
		margin-left: 1.6rem;
	}
}