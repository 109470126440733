.popup-transfer-tickets {
	position: relative;
	border-radius: 14px;

	background: rgba(222,222,222,1);
	background: -moz-linear-gradient(top, rgba(222,222,222,1) 0%, rgba(201,199,200,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(222,222,222,1)), color-stop(100%, rgba(201,199,200,1)));
	background: -webkit-linear-gradient(top, rgba(222,222,222,1) 0%, rgba(201,199,200,1) 100%);
	background: -o-linear-gradient(top, rgba(222,222,222,1) 0%, rgba(201,199,200,1) 100%);
	background: -ms-linear-gradient(top, rgba(222,222,222,1) 0%, rgba(201,199,200,1) 100%);
	background: linear-gradient(to bottom, rgba(222,222,222,1) 0%, rgba(201,199,200,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dedede', endColorstr='#c9c7c8', GradientType=0 );
}
.popup-transfer-tickets .popup-trigger {
	position: absolute;
	cursor: pointer;

	top: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
	right: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
}
.popup-transfer-tickets .popup-trigger svg {
	width: calc(24px + (72 - 24) * ((100vw - 350px) / (1019 - 350)));
	height: calc(24px + (72 - 24) * ((100vw - 350px) / (1019 - 350)));
}
.popup-transfer-tickets .popup-trigger svg .cls-1 {
	transition: fill 0.2s linear;
	fill: #929292;
}
.popup-transfer-tickets .popup-trigger:hover svg .cls-1 {
	fill: #505050;
}

.popup-transfer-tickets .popup-title {
	line-height: 1.2;
	font-weight: 500;
	color: #505050;

	font-size: calc(16px + (48 - 16) * ((100vw - 350px) / (1019 - 350)));
	padding-top: calc(15px + (45 - 15) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(12px + (37 - 12) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(14px + (42 - 14) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(14px + (42 - 14) * ((100vw - 350px) / (1019 - 350)));
}

.popup-transfer-tickets .tabs-for-ticketsList {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    padding-left: calc(14px + (42 - 14) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(14px + (42 - 14) * ((100vw - 350px) / (1019 - 350)));
}
.popup-transfer-tickets .tabs-for-ticketsList > .for-tab {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;

	line-height: 1;
	font-weight: 400;
	letter-spacing: -0.1px;
	text-decoration: none;
	border: none;
	border-radius: 8px 8px 0 0;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0.5rem;
    padding-right: 0.5rem;
    transition: background-color 0.2s linear, color 0.2s linear;
    background-color: #929192;
    color: #fff;

    width: calc(25% - 30px);

	height: calc(27px + (81 - 27) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(13px + (40 - 13) * ((100vw - 350px) / (1019 - 350)));

	cursor: default;
}
.popup-transfer-tickets .tabs-for-ticketsList > .for-tab:hover,
.popup-transfer-tickets .tabs-for-ticketsList > .for-tab.active {
    background-color: #f6f5f5;
    color: #505050;
	cursor: pointer;
}

.popup-transfer-tickets .tabs-for-ticketsList > .for-tab:not(:last-child) {
	margin-right: 1rem;
}

.popup-transfer-tickets .tickets-list {
	margin-left: calc(14px + (42 - 14) * ((100vw - 350px) / (1019 - 350)));
	margin-right: calc(14px + (42 - 14) * ((100vw - 350px) / (1019 - 350)));
	padding-left: 1.7rem;
	padding-right: 1.7rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-radius: 0 8px 8px 8px;
	background-color: #f6f5f5;
}
.popup-transfer-tickets .tickets-list .tickets-list-content {
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	
	max-height: 40vh;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.popup-transfer-tickets .tickets-list .tickets-list-content::-webkit-scrollbar {
	width: 1.4rem;
}
.popup-transfer-tickets .tickets-list .tickets-list-content::-webkit-scrollbar-track {
	background-image: repeating-linear-gradient(to right, transparent, transparent 5px, #b4b4b4 5px, #b4b4b4 9px, transparent 9px);
}
.popup-transfer-tickets .tickets-list .tickets-list-content::-webkit-scrollbar-thumb {
	background: #585858;
	border-radius: 6px;
}
.popup-transfer-tickets .tickets-list button {
	width: 100%;

	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;
	
	padding-top: 0;
	padding-bottom: 0;
    border: none;
    border-radius: 8px;
    font-size: 0;
	line-height: 1;
	font-weight: 300;
	transition: all 0s linear;

    background-color: #dddcdc;
    box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.31);
	
	height: calc(31px + (95 - 31) * ((100vw - 350px) / (1019 - 350)));
    padding-left: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
    padding-right: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
}
.popup-transfer-tickets .tickets-list button.active {
    background-color: #929192;
}
.popup-transfer-tickets .tickets-list button + button {
	margin-top: 2rem;
}
.popup-transfer-tickets .tickets-list button .ticket-name {
	-ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
	
	line-height: 1;
	font-weight: 300;
    text-align: left;
    padding-top: 2px;
    color: #595959;

    font-size: calc(12px + (36 - 12) * ((100vw - 350px) / (1019 - 350)));
    padding-right: calc(5px + (15 - 5) * ((100vw - 350px) / (1019 - 350)));
}
.popup-transfer-tickets .tickets-list button.active .ticket-name {
    color: #fff;
}
.popup-transfer-tickets .tickets-list button .methode {
	-ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
	
	line-height: 1;
	font-weight: 500;
    text-align: right;
    color: #5b5b5b;

	display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: calc(8px + (24 - 8) * ((100vw - 350px) / (1019 - 350)));
    height: calc(8px + (25 - 8) * ((100vw - 350px) / (1019 - 350)));
}
.popup-transfer-tickets .tickets-list button:active .methode {
    color: #fff;
}

.popup-transfer-tickets .action-buttons {
	display: -ms-flexbox;
    display: flex;

	margin-left: calc(14px + (42 - 14) * ((100vw - 350px) / (1019 - 350)));
	margin-right: calc(14px + (42 - 14) * ((100vw - 350px) / (1019 - 350)));
	padding-top: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(20px + (60 - 20) * ((100vw - 350px) / (1019 - 350)));
}
.popup-transfer-tickets .action-buttons button {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;

    width: calc(50% - 10px);
	line-height: 1;
	font-weight: 400;
	letter-spacing: -0.1px;
	border: none;
	border-radius: 8px;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0.5rem;
    padding-right: 0.5rem;
    transition: color 0.2s linear;
    color: #fff;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.51);

	height: calc(27px + (81 - 27) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(13px + (40 - 13) * ((100vw - 350px) / (1019 - 350)));
}
.popup-transfer-tickets .action-buttons button.priority-action {
	background: rgba(255,93,93,1);
	background: -moz-linear-gradient(top, rgba(255,93,93,1) 0%, rgba(231,84,84,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,93,93,1)), color-stop(100%, rgba(231,84,84,1)));
	background: -webkit-linear-gradient(top, rgba(255,93,93,1) 0%, rgba(231,84,84,1) 100%);
	background: -o-linear-gradient(top, rgba(255,93,93,1) 0%, rgba(231,84,84,1) 100%);
	background: -ms-linear-gradient(top, rgba(255,93,93,1) 0%, rgba(231,84,84,1) 100%);
	background: linear-gradient(to bottom, rgba(255,93,93,1) 0%, rgba(231,84,84,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5d5d', endColorstr='#e75454', GradientType=0 );
}
.popup-transfer-tickets .action-buttons button.normal-action {
	background: rgba(33,201,167,1);
	background: -moz-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(33,201,167,1)), color-stop(100%, rgba(30,181,151,1)));
	background: -webkit-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: -o-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: -ms-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: linear-gradient(to bottom, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#21c9a7', endColorstr='#1eb597', GradientType=0 );
}
.popup-transfer-tickets .action-buttons button + button {
	margin-left: 2rem;
}
.popup-transfer-tickets .action-buttons button:not(.disabled):hover {
	color: #505050;
}

.popup-transfer-tickets .action-buttons button.disabled {
	opacity: 0.4;
	cursor: default;
}

@media (min-width: 1020px) {
	.popup-transfer-tickets .popup-trigger {
		top: 3rem;
		right: 3rem;
	}
	.popup-transfer-tickets .popup-trigger svg {
		width: 7.2rem;
		height: 7.2rem;
	}
	.popup-transfer-tickets .popup-title {
		font-size: 4.8rem;
		padding-top: 4.5rem;
		padding-bottom: 3.7rem;
		padding-left: 4.2rem;
		padding-right: 4.2rem;
	}
	.popup-transfer-tickets .tabs-for-ticketsList {
	    padding-left: 4.2rem;
	    padding-right: 4.2rem;
	}
	.popup-transfer-tickets .tabs-for-ticketsList > .for-tab {
		height: 8.1rem;
		font-size: 4rem;
	}
	.popup-transfer-tickets .tickets-list {
		margin-right: 4.2rem;
		margin-left: 4.2rem;
	}
	.popup-transfer-tickets .tickets-list button {
		padding-left: 3rem;
		padding-right: 3rem;
	    height: 9.5rem;
	}
	.popup-transfer-tickets .tickets-list button .ticket-name {
		font-size: 3.6rem;
	    padding-right: 1.5rem;
	}
	.popup-transfer-tickets .tickets-list button .methode {
		font-size: 2.4rem;
	    height: 2.5rem;
	}
	.popup-transfer-tickets .action-buttons {
		margin-left: 4.2rem;
		margin-right: 4.2rem;
		padding-top: 3rem;
		padding-bottom: 6rem;
	}
	.popup-transfer-tickets .action-buttons button {
		height: 8.1rem;
		font-size: 4rem;
	}
}

@media (max-width: 800px) {
	.popup-transfer-tickets .tabs-for-ticketsList > .for-tab {
	    width: calc(33.33333333333333% - 20px);
	}
	.popup-transfer-tickets .tickets-list {
		padding-left: 0.8rem;
		padding-right: 0.8rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
	.popup-transfer-tickets .tickets-list .tickets-list-content::-webkit-scrollbar {
		width: 1rem;
	}
	.popup-transfer-tickets .tickets-list .tickets-list-content::-webkit-scrollbar-track {
		background-image: repeating-linear-gradient(to right, transparent, transparent 4px, #b4b4b4 4px, #b4b4b4 6px, transparent 6px);
	}
	.popup-transfer-tickets .tickets-list .tickets-list-content::-webkit-scrollbar-thumb {
		border-radius: 4px;
	}
	.popup-transfer-tickets .tickets-list button + button {
		margin-top: 1.4rem;
	}
	.popup-transfer-tickets .action-buttons button {
	    width: calc(50% - 7px);
	}
	.popup-transfer-tickets .action-buttons button + button {
		margin-left: 1.4rem;
	}
}

@media (max-width: 600px) {
	.popup-transfer-tickets .tickets-list .tickets-list-content::-webkit-scrollbar {
		width: 0.6rem;
	}
	.popup-transfer-tickets .tickets-list .tickets-list-content::-webkit-scrollbar-track {
		background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #b4b4b4 2px, #b4b4b4 4px, transparent 4px);
	}
	.popup-transfer-tickets .tickets-list .tickets-list-content::-webkit-scrollbar-thumb {
		border-radius: 2px;
	}
	.popup-transfer-tickets .tickets-list button + button {
		margin-top: 1rem;
	}
	.popup-transfer-tickets .action-buttons button {
	    width: calc(50% - 5px);
	}
	.popup-transfer-tickets .action-buttons button + button {
		margin-left: 1rem;
	}
}