.popup-dispenser-content {
	border-radius: 14px;

	padding-top: calc(27px + (81 - 27) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(27px + (81 - 27) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(27px + (81 - 27) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(27px + (81 - 27) * ((100vw - 350px) / (1019 - 350)));

	background: rgba(255,255,255,1);
	background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(231,230,230,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(231,230,230,1)));
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(231,230,230,1) 100%);
	background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(231,230,230,1) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(231,230,230,1) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(231,230,230,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e7e6e6', GradientType=0 );
}

.popup-dispenser-content .popup-title {
	line-height: 1.2;
	font-weight: 500;
	color: #505050;
	border-bottom: 1px solid #c2c2c2;

	font-size: calc(16px + (48 - 16) * ((100vw - 350px) / (1019 - 350)));
	padding-bottom: calc(12px + (37 - 12) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(23px + (69 - 23) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(23px + (69 - 23) * ((100vw - 350px) / (1019 - 350)));
}

.popup-dispenser-content .buttons-section {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.popup-dispenser-content .buttons-section button {
	width: 100%;

	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    position: relative;
	padding-top: 0;
	padding-bottom: 0;
	border: none;
	line-height: 1;
	font-weight: 400;
	letter-spacing: -0.1px;
	text-align: left;
	border-radius: 9px;
	margin-bottom: 2rem;
	transition: background-color 0.2s linear, color 0.2s linear, box-shadow 0.2s linear;
	background-color: #c9c9c9;
	color: #505050;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0);

	height: calc(36px + (110 - 36) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(15px + (45 - 15) * ((100vw - 350px) / (1019 - 350)));
	padding-right: calc(51px + (155 - 51) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(15px + (47 - 15) * ((100vw - 350px) / (1019 - 350)));
}
.popup-dispenser-content .buttons-section button:last-child {
	margin-bottom: 0;
}
.popup-dispenser-content .buttons-section button.active,
.popup-dispenser-content .buttons-section button:not(.disabledButton):hover {
	background-color: #6a6a6a;
	color: #fff;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.31);
}

.smartq-popup .popup-container .popup-content button.disabledButton {
	opacity: 0.6;
}
.popup-dispenser-content .buttons-section button .icon {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;

	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	font-size: 0;
	line-height: 1;
	letter-spacing: initial;
	border-radius: 0 8px 8px 0;

	background: rgba(251,197,1,1);
	background: -moz-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(251,197,1,1)), color-stop(100%, rgba(242,137,0,1)));
	background: -webkit-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -o-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -ms-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: linear-gradient(to bottom, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbc501', endColorstr='#f28900', GradientType=0 );

	width: calc(36px + (110 - 36) * ((100vw - 350px) / (1019 - 350)));
}
.popup-dispenser-content .buttons-section button:not(.active) .icon {
	display: none;
}
.popup-dispenser-content .buttons-section button .icon svg {
	width: calc(23px + (71 - 23) * ((100vw - 350px) / (1019 - 350)));
	height: calc(23px + (71 - 23) * ((100vw - 350px) / (1019 - 350)));
}
.popup-dispenser-content .buttons-section button .icon svg .st8 {
	transition: fill 0.2s linear;
	fill:#505050;
}
.popup-dispenser-content .buttons-section button:hover .icon svg .st8 {
	fill:#fff;
}
.popup-dispenser-content .buttons-section button .texts {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-top: 2px;
	padding-bottom: 2px;
}

.popup-dispenser-content .action-buttons {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

	margin-top: calc(22px + (66 - 22) * ((100vw - 350px) / (1019 - 350)));
}
.popup-dispenser-content .action-buttons button {
	width: calc(50% - 15px);

	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

	line-height: 1;
	font-weight: 600;
	letter-spacing: -0.25px;
	border: none;
	border-radius: 8px;
	padding-top: 0;
	padding-bottom: 0;
	color: #fff;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.51);

	height: calc(40px + (121 - 40) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(17px + (51 - 17) * ((100vw - 350px) / (1019 - 350)));
	padding-left: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));
    padding-right: calc(10px + (30 - 10) * ((100vw - 350px) / (1019 - 350)));

    transition: color 0.2s linear;
}
.popup-dispenser-content .action-buttons button.back-action {
	background: rgba(80,80,80,1);
	background: -moz-linear-gradient(top, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(80,80,80,1)), color-stop(100%, rgba(69,69,69,1)));
	background: -webkit-linear-gradient(top, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	background: -o-linear-gradient(top, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	background: -ms-linear-gradient(top, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	background: linear-gradient(to bottom, rgba(80,80,80,1) 0%, rgba(69,69,69,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#505050', endColorstr='#454545', GradientType=0 );
}
.popup-dispenser-content .action-buttons button.done {
	background: rgba(33,201,167,1);
	background: -moz-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(33,201,167,1)), color-stop(100%, rgba(30,181,151,1)));
	background: -webkit-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: -o-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: -ms-linear-gradient(top, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	background: linear-gradient(to bottom, rgba(33,201,167,1) 0%, rgba(30,181,151,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#21c9a7', endColorstr='#1eb597', GradientType=0 );
}
.popup-dispenser-content .action-buttons button.back-action:hover {
	color: #21c9a7;
}
.popup-dispenser-content .action-buttons button.done:hover {
	color: #505050;
}
.popup-dispenser-content .action-buttons button + button {
	margin-left: 3rem;
}
.popup-dispenser-content .action-buttons button .icon {
	font-size: 0;
	line-height: 1;
	letter-spacing: initial;
	margin-right: calc(8px + (25 - 8) * ((100vw - 350px) / (1019 - 350)));
}
.popup-dispenser-content .action-buttons button .icon svg {
	width: calc(23px + (71 - 23) * ((100vw - 350px) / (1019 - 350)));
	height: calc(23px + (71 - 23) * ((100vw - 350px) / (1019 - 350)));
}
.popup-dispenser-content .action-buttons button .icon svg .st9,
.popup-dispenser-content .action-buttons button .icon svg .st10 {
	transition: fill 0.2s linear;
	fill: #fff;
}
.popup-dispenser-content .action-buttons button.back-action:hover .icon svg .st9 {
	fill: #21c9a7;
}
.popup-dispenser-content .action-buttons button.done:hover .icon svg .st10 {
	fill: #505050;
}
.popup-dispenser-content .action-buttons button .texts {
	padding-top: 1px;
}

@media (min-width: 1020px) {
	.popup-dispenser-content {
		padding-top: 8.1rem;
		padding-bottom: 8.1rem;
		padding-left: 8.1rem;
		padding-right: 8.1rem;
	}

	.popup-dispenser-content .popup-title {
		font-size: 4.8rem;
		padding-bottom: 3.7rem;
		padding-left: 6.9rem;
		padding-right: 6.9rem;
	}

	.popup-dispenser-content .buttons-section button {
		height: 11rem;
		padding-left: 4.5rem;
		padding-right: 15.5rem;
		font-size: 4.7rem;
	}
	.popup-dispenser-content .buttons-section button .icon {
		width: 11rem;
	}
	.popup-dispenser-content .buttons-section button .icon svg {
		width: 7.1rem;
		height: 7.1rem;
	}

	.popup-dispenser-content .action-buttons {
		margin-top: 6.6rem;
	}
	.popup-dispenser-content .action-buttons button {
		height: 12.1rem;
		font-size: 5.1rem;
		padding-left: 3rem;
		padding-right: 3rem;
	}
	.popup-dispenser-content .action-buttons button .icon {
		margin-right: 2.5rem;
	}
	.popup-dispenser-content .action-buttons button .icon svg {
		width: 7.1rem;
		height: 7.1rem;
	}
}

@media (max-width: 800px) {
	.popup-dispenser-content .buttons-section button {
		margin-bottom: 1.4rem;
	}

	.popup-dispenser-content .action-buttons button {
		width: calc(50% - 11px);
	}
	.popup-dispenser-content .action-buttons button + button {
		margin-left: 2.2rem;
	}
}

@media (max-width: 600px) {
	.popup-dispenser-content .buttons-section button {
		margin-bottom: 1rem;
	}

	.popup-dispenser-content .action-buttons button {
		width: calc(50% - 8px);
	}
	.popup-dispenser-content .action-buttons button + button {
		margin-left: 1.6rem;
	}
}