.operator-lists .dropped-tickets .dropped-buttons {
	display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    max-height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;

	padding-right: 0.5rem;
}
.operator-lists .dropped-tickets .dropped-buttons::-webkit-scrollbar {
	width: 1.4rem;
}
.operator-lists .dropped-tickets .dropped-buttons::-webkit-scrollbar-track {
	background-image: repeating-linear-gradient(to right, transparent, transparent 5px, #b4b4b4 5px, #b4b4b4 9px, transparent 9px);
}
.operator-lists .dropped-tickets .dropped-buttons::-webkit-scrollbar-thumb {
	background: #585858;
	border-radius: 6px;
}
.operator-lists .dropped-tickets .dropped-buttons button {
	display: -ms-flexbox;
    display: flex;

    -ms-flex-align: center;
    align-items: center;

    -ms-flex-pack: center;
    justify-content: center;

	width: calc(25% - 20px);
	margin: 1rem;
	border-radius: 8px;
	border: none;
	line-height: 1;
	font-weight: 500;

	transition: background-color 0.2s linear, color 0.2s linear;
	color: #4f4f4f;
	background-color: #f6f5f5;
	box-shadow: 2px 2px 6px 0px rgba(17,12,14,0.31);
	padding: 0 0.5rem;

	height: calc(31px + (95 - 31) * ((100vw - 350px) / (1019 - 350)));
	font-size: calc(18px + (54 - 18) * ((100vw - 350px) / (1019 - 350)));
}
.operator-lists .dropped-tickets .dropped-buttons button:hover {
	background-color: #929192;
	color: #fff;
}
.operator-lists .dropped-tickets .dropped-buttons button.active {
	background: rgba(251,197,1,1);
	background: -moz-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(251,197,1,1)), color-stop(100%, rgba(242,137,0,1)));
	background: -webkit-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -o-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: -ms-linear-gradient(top, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	background: linear-gradient(to bottom, rgba(251,197,1,1) 0%, rgba(242,137,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbc501', endColorstr='#f28900', GradientType=0 );

	color: #505050;
}
.operator-lists .dropped-tickets .dropped-buttons button.active:hover {
	color: #fff;
}

@media (min-width: 1020px) {
	.operator-lists .dropped-tickets .dropped-buttons button {
		height: 9.5rem;
		font-size: 5.4rem;
	}
}

@media (max-width: 800px) {
	.operator-lists .dropped-tickets .dropped-buttons {
		padding-right: 0.6rem;
	}
	.operator-lists .dropped-tickets .dropped-buttons::-webkit-scrollbar {
		width: 1rem;
	}
	.operator-lists .dropped-tickets .dropped-buttons::-webkit-scrollbar-track {
		background-image: repeating-linear-gradient(to right, transparent, transparent 4px, #b4b4b4 4px, #b4b4b4 6px, transparent 6px);
	}
	.operator-lists .dropped-tickets .dropped-buttons::-webkit-scrollbar-thumb {
		border-radius: 4px;
	}
	.operator-lists .dropped-tickets .dropped-buttons button {
		width: calc(25% - 14px);
		margin: 0.7rem;
	}
}

@media (max-width: 600px) {
	.operator-lists .dropped-tickets .dropped-buttons {
		padding-right: 0.7rem;
	}
	.operator-lists .dropped-tickets .dropped-buttons::-webkit-scrollbar {
		width: 0.6rem;
	}
	.operator-lists .dropped-tickets .dropped-buttons::-webkit-scrollbar-track {
		background-image: repeating-linear-gradient(to right, transparent, transparent 2px, #b4b4b4 2px, #b4b4b4 4px, transparent 4px);
	}
	.operator-lists .dropped-tickets .dropped-buttons::-webkit-scrollbar-thumb {
		border-radius: 2px;
	}
	.operator-lists .dropped-tickets .dropped-buttons button {
		width: calc(25% - 10px);
		margin: 0.5rem;
	}
}